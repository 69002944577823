
class DemoService {

    getDemoEmails() {
        return [
            "giveawayinline.demo@worximity.com",
            "giveawaysampling.demo@worximity.com",
            "quality.demo@worximity.com",
            "tv@wxmachineshop1.com"
        ];
    }

    maskFactoryNameIfNecessary(email, factoryName, index) {
        return !this.getDemoEmails().includes(email) ? factoryName : "Factory " + index;
    }

    maskProductionUnitNameIfNecessary(email, productionUnitName, index) {
        return !this.getDemoEmails().includes(email) ? productionUnitName : "Production Unit " + index;
    }

    replaceProductionUnitNameIfNecessary(email, productionUnitName, maskedName) {
        return !this.getDemoEmails().includes(email) ? productionUnitName : maskedName;
    }

    maskProductNameIfNecessary(email, productName) {
        return !this.getDemoEmails().includes(email) ? productName : "";
    }
};

export default new DemoService();