import axios from "axios";
import store from "@/store";
// import { getTokenRedirect } from "./authentication/authRedirect";
// import { tokenRequest } from "./authentication/authConfig";

const http = axios.create({
  baseURL: "https://tileplusapimgmt-staging.azure-api.net/vars", //process.env.VUE_APP_DASHBOARD_API_BASE_URL,
});

http.interceptors.request.use(async (req) => {
  const zToken = store.getters["user/zToken"];
  const uToken = store.getters["user/uToken"];
  // const token = await getTokenRedirect(tokenRequest);
  req.headers.Authorization = zToken;
  req.headers["X-U"] = uToken;
  if (req.data) req.headers["Content-Type"] = "application/json";

  return req;
});

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // if (error.response.status === 401) {
    //   signOut();
    // }
    throw error;
  },
);

export default http;
