import { DateTime, Duration } from "luxon";
import PackageService from "@/components/PackageService";
import ErrorHandling from "@/components/ErrorHandling";
import i18n from "@/i18n";

export default {
  namespaced: true,

  state: {
    packageDefinitions: {},
    lastRefreshDate: DateTime.fromMillis(1), // start of time
    packageRefreshTimeout: Duration.fromISO("PT1H").toMillis(),
  },

  getters: {
    packageNameByFeatureName(state) {
      let packageNameByFeatureName = new Map();
      state.packageDefinitions.forEach((def) => {
        def.features.forEach((feature) => packageNameByFeatureName.set(feature, def.name));
      });
      return packageNameByFeatureName;
    },
    featuresByProductionUnitId(state, getters, rootState, rootGetters) {
      let featuresByProductionUnitId = new Map();
      rootGetters["navigation/activeFactoryProductionUnits"].forEach((pu) => {
        let features = [];
        pu.packages.forEach((puPackage) => {
          if (state.packageDefinitions instanceof Array) {
            let packageDefinition = state.packageDefinitions.find((def) => def.name === puPackage);
            if (packageDefinition) {
              features.push(...packageDefinition.features);
            }
          }
        });
        featuresByProductionUnitId.set(pu.id, features);
      });
      return featuresByProductionUnitId;
    },
    activePuHasRequiredFeature: (state, getters, rootState, rootGetters) => (componentRequiredFeature) => {
      let features = getters.featuresByProductionUnitId.get(rootGetters["dashboard/activeProductionUnitId"]);
      return features ? features.includes(componentRequiredFeature) : false;
    },
    puHasRequiredFeature: (state, getters) => (productionUnitId, componentRequiredFeature) => {
      let features = getters.featuresByProductionUnitId.get(productionUnitId);
      return features ? features.includes(componentRequiredFeature) : false;
    },
  },

  actions: {
    fetchPackageDefinitions({ state, commit }) {
      if (DateTime.now().toMillis() - state.lastRefreshDate.toMillis() < state.packageRefreshTimeout) return;

      // if (rootGetters["user/isLoggedIn"]) {
      PackageService.getPackageDefinitions()
        .then((response) => {
          commit("setPackageDefinitions", response.data);
        })
        .catch((error) => {
          commit(
            "operation/showOperationError",
            ErrorHandling.buildErrorsMessages(error.response, (code) =>
              i18n.t("common.errors.default", { code: code }),
            ),
            { root: true },
          );
        });
      // }
    },
  },
  mutations: {
    setPackageDefinitions(state, packageDefinitions) {
      state.packageDefinitions = packageDefinitions;
      state.lastRefreshDate = DateTime.now();
    },
  },
};
