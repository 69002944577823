import { DateTime } from "luxon";
import * as TimeUtils from "@/store/TimeUtils";
//import TileService from "@/components/dashboard/tiles/TileService";
import UserService from "@/components/user/UserService";
import { PRODUCTION_DAY } from "@/store/TimeUtils";
//import FactoryService from "@/components/factory/FactoryService";

export default {
  namespaced: true,

  state: {
    activeFactory: null,
    activeFactoryDataSourceAlerts: [],

    // Properties used by the TimeFrameDatePicker
    timeFrame: TimeUtils.PRODUCTION_DAY,
    date: null,
    dateData: {
      hour: null,
      shiftIndex: null,
      isLastShift: null,
      runIndex: null,
      isLastRun: null,
    },
    isLiveData: true,
    selectedProductionRun: null,
    selectedWorkShift: null,

    // Properties used by views
    startDateTime: null, // DateTime
    endDateTime: null, // DateTime
    currentDateTime: null, // DateTime
    offsetFromMidnightMillis: 0,
    workShiftCoverage: [],
    productionRunCoverage: [],

    /**
     * The isTimeRangeReady is used by views to know when to re-fetch data.
     *
     * Views watch this property and when it switches from 'false' to 'true', they should
     * re-fetch data with the new values for 'startDateTime' and 'endDateTime'
     */
    isTimeRangeReady: true,

    factoryUsers: [],
    factoryTags: [],
  },

  getters: {
    activeFactory(state) {
      return state.activeFactory;
    },
    activeFactoryId(state) {
      return state.activeFactory.id;
    },
    factoryTags(state) {
      return state.factoryTags;
    },
    activeFactoryProductionUnits(state) {
      return state.activeFactory?.productionUnits ?? [];
    },
    activeFactoryDataSourceAlerts(state) {
      return state.activeFactoryDataSourceAlerts;
    },
    timeFrame(state) {
      return state.timeFrame;
    },
    currentDateTime(state) {
      return state.currentDateTime;
    },
    date(state) {
      return state.date;
    },
    dateData(state) {
      return state.dateData;
    },
    isLiveData(state) {
      return state.isLiveData;
    },
    factoryUsers(state) {
      return state.factoryUsers;
    },
    startDateTime(state) {
      return state.startDateTime;
    },
    startISO(state) {
      return state.startDateTime?.toISO();
    },
    /**
     * The endTime getters are represented as function so that they execute every time.
     * Or else, they're value gets cached and only re-evaluated when state.endDateTime changes.
     *
     * To get the current time if state.endDateTime is null (live work shift or production run),
     * the function needs to execute every call.
     *
     * To differentiate them they are prefixed with "get", since they must be called
     * as a function and not a value/property.
     */
    endDateTime(state) {
      return state.endDateTime ? state.endDateTime : state.currentDateTime;
    },
    endISO(state) {
      return state.endDateTime ? state.endDateTime.toISO() : state.currentDateTime.toISO();
    },
    offsetFromMidnightMillis(state) {
      return state.offsetFromMidnightMillis;
    },
    durationMillis(state) {
      const start = state.startDateTime?.toMillis();
      const end = state.endDateTime ? state.endDateTime.toMillis() : state.currentDateTime?.toMillis();
      if (!start) return 0;
      return end - start;
    },
    selectedProductionRun(state) {
      return state.selectedProductionRun;
    },
    selectedWorkShift(state) {
      return state.selectedWorkShift;
    },
    isTimeRangeReady(state) {
      return state.isTimeRangeReady;
    },
  },

  actions: {
    setActiveFactory({ getters, commit, dispatch, rootGetters }, activeFactory) {
      if (activeFactory.id !== getters.activeFactory?.id) {
        commit("setActiveFactory", activeFactory);

        // set the time frame data on active factory set
        // if date is null, it means this is the first factory set
        // so default to today and PRODUCTION_DAY timeframe
        // if date is NOT null, the timeframe has already been set,
        // so keep the same one with the new factory
        const date = TimeUtils.getBusinessDateTime(
          DateTime.now().setZone(activeFactory.timezone),
          activeFactory.productionDayMinutesFromMidnight,
          activeFactory.isCalendarDayBusinessDay,
        );
        dispatch("setTimeFrame", {
          timeFrame: PRODUCTION_DAY,
          date: date,
          hour: null,
          shiftIndex: null,
          runIndex: null,
          isLastShift: null,
          isLastRun: null,
        });

        if (rootGetters["user/isAdmin"]) {
          dispatch("fetchFactoryUsers", activeFactory.id);
          dispatch("devicesAdmin/fetchActiveFactoryDevices", activeFactory.id, { root: true });
          dispatch("devicesAdmin/fetchActiveFactoryDataSources", activeFactory.id, { root: true });
        }

        dispatch("fetchFactoryTags", activeFactory.id);
      }
    },
    async setTimeFrame(
      { state, commit, dispatch },
      { timeFrame, date, hour, shiftIndex, runIndex, isLastShift, isLastRun, selectedWorkShift, selectedProductionRun },
    ) {
      commit("setProperty", ["timeFrame", timeFrame]);
      commit("setProperty", ["date", date]);
      commit("setProperty", ["dateData", { hour, shiftIndex, runIndex }]);
      commit("setProperty", ["selectedWorkShift", selectedWorkShift]);
      commit("setProperty", ["selectedProductionRun", selectedProductionRun]);
      const currentBusinessDay = TimeUtils.getBusinessDateTime(
        DateTime.now().setZone(state.activeFactory.timeZone),
        state.activeFactory.productionDayMinutesFromMidnight,
        state.activeFactory.isCalendarDayBusinessDay,
      );
      const currentHour = DateTime.now()
        .setZone(state.activeFactory.timeZone)
        .set({ minute: 0, second: 0, millisecond: 0 })
        .get("hour");
      let isLiveData = false;
      let startDateTime = null;
      let endDateTime = null;
      switch (timeFrame) {
        case TimeUtils.HOUR: {
          // check if hour is live data
          isLiveData =
            date?.toFormat(TimeUtils.DATE_FORMAT) === currentBusinessDay.toFormat(TimeUtils.DATE_FORMAT) &&
            currentHour === hour;
          // show the entire hour
          const { start, end } = TimeUtils.getHourTimeRange(date, hour);
          startDateTime = start;
          endDateTime = end;
          break;
        }
        case TimeUtils.SHIFT: {
          // check if shift is the current one
          isLiveData = isLastShift;
          // show the entire shift or until now
          const { start, end } = TimeUtils.getWorkShiftTimeRange(selectedWorkShift);
          startDateTime = start;
          endDateTime = end;
          break;
        }
        case TimeUtils.PRODUCTION_DAY: {
          // check if current production day
          isLiveData = date?.toFormat(TimeUtils.DATE_FORMAT) === currentBusinessDay.toFormat(TimeUtils.DATE_FORMAT);
          // show the entire production day
          const { start, end } = await TimeUtils.getProductionDayTimeRange(
            date.toFormat(TimeUtils.DATE_FORMAT),
            state.activeFactory.productionUnits[0].id,
            state.activeFactory.timezone
          );
          startDateTime = start;
          endDateTime = end;
          break;
        }
        case TimeUtils.PRODUCTION_RUN: {
          // check if production run is the current one
          isLiveData = isLastRun;
          // show the entire production run or until now
          const { start, end } = TimeUtils.getProductionRunTimeRange(selectedProductionRun);
          startDateTime = start;
          endDateTime = end;
          break;
        }
      }
      dispatch("setCurrentDateTime");
      commit("setProperty", ["isLiveData", isLiveData]);
      commit("setProperty", ["startDateTime", startDateTime]);
      commit("setProperty", ["endDateTime", endDateTime]);
      commit("setOffsetFromMidnight");
      commit("setProperty", ["isTimeRangeReady", !state.isTimeRangeReady]); // triggers views to re-fetch their data
    },
    fetchFactoryDataSourceAlerts({ commit }) {
      commit("setActiveFactoryDataSourceAlerts", []);
      // if (state.activeFactory) {
      //   TileService.getDataSourceAlerts(state.activeFactory.id)
      //     .then((response) => {
      //       commit("setActiveFactoryDataSourceAlerts", response.data);
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //       commit("setActiveFactoryDataSourceAlerts", []);
      //     });
      // }
    },
    fetchFactoryUsers({ commit }, factoryId) {
      UserService.getUsers(factoryId)
        .then((httpResponse) => {
          commit("setFactoryUsers", httpResponse.data);
        })
        .catch((error) => {
          console.log(error);
          commit("setFactoryUsers", []);
        });
    },
    fetchFactoryTags({ commit }) {
      commit("setFactoryTags", []);
      // FactoryService.getFactoryTags(factoryId)
      //   .then((httpResponse) => {
      //     commit("setFactoryTags", httpResponse.data);
      //   })
      //   .catch((error) => {
      //     console.log(factoryId);
      //     console.log(error);
      //     commit("setFactoryTags", []);
      //   });
    },
    setCurrentDateTime({ state, commit }) {
      if (!state.activeFactory) return;
      const currentDateTime = DateTime.now().setZone(state.activeFactory.timezone);
      commit("setProperty", ["currentDateTime", currentDateTime]);
    },
  },

  mutations: {
    setActiveFactory(state, data) {
      state.activeFactory = data;
    },
    setActiveFactoryDataSourceAlerts(state, alerts) {
      state.activeFactoryDataSourceAlerts = alerts;
    },
    setFactoryUsers(state, value) {
      state.factoryUsers = value;
    },
    setFactoryTags(state, value) {
      state.factoryTags = value;
    },
    setProperty(state, [property, value]) {
      state[property] = value;
    },
    setOffsetFromMidnight(state) {
      const startAtMidnightMillis = state.startDateTime.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toMillis();
      const startMillis = state.startDateTime.toMillis();
      state.offsetFromMidnightMillis = startMillis - startAtMidnightMillis;
    }
  },
};
