<template>
  <v-dialog
    v-if="notificationActive !== null"
    overlay-color="var(--color-overlayBackground)"
    width="unset"
    :value="value"
    @input="$emit('input', $event)"
  >
    <div class="content">
      <v-btn icon large @click="closeNotificationDialog" :aria-label="$t('common.close')" class="close-btn">
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <p class="wx-subtle-text">{{ notificationActive.date }}</p>

      <h2 class="wx-typo-h1 dialog-title">{{ notificationActive.title }}</h2>

      <p v-for="(paragraph, index) in notificationActive.paragraphs" :key="'paragraph' + index">
        {{ paragraph }}
      </p>

      <div class="actions">
        <wx-btn-icon
          color="primary"
          :disabled="notificationActiveIsFirst"
          @click="selectPreviousNotification"
          aria-label="$t('notifications.previousNotification')"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </wx-btn-icon>

        <span class="notificationCounter">{{ notificationActiveIndex + 1 }} / {{ notificationQuantity }}</span>

        <wx-btn-icon
          color="primary"
          :disabled="notificationActiveIsLast"
          @click="selectNextNotification"
          aria-label="$t('notifications.nextNotification')"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </wx-btn-icon>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import WxBtnIcon from "@/components/ui/WxBtnIcon";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "NotificationDialog",
  components: {
    WxBtnIcon,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters("notifications", [
      "notificationActive",
      "notificationQuantity",
      "notificationActiveIndex",
      "notificationActiveIsFirst",
      "notificationActiveIsLast",
    ]),
  },
  methods: {
    ...mapActions("notifications", ["closeNotificationDialog", "selectPreviousNotification", "selectNextNotification"]),
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-dialog {
  min-width: 256px;
  background: var(--color-flat-panel-modal-theme);

  @media ($wx-sm-min) {
    max-width: 540px;
  }

  @media ($wx-md-min) {
    max-width: 720px;
  }
}

.close-btn {
  position: absolute;
  top: var(--dialog-close-offset);
  right: var(--dialog-close-offset);
}

.content {
  position: relative;
  width: auto;
  max-width: 100%;
  padding: var(--dialog-padding);
}

.dialog-title {
  margin-bottom: 16px;
}

.actions {
  display: flex;
  align-items: center;
  margin-top: 24px;
}

.notificationCounter {
  margin: 0 12px;
  font-weight: bold;
  font-size: 1.25rem;
}
</style>
