<template>
  <fragment>
    <v-app-bar class="wxapp-main-header" :app="isMobile" :absolute="!isMobile" :color="appBarColor">
      <v-row class="flex-row-reverse mt-0 mb-0">
        <!--
          Right column
          The only of the 3 cols that stays visible for all breakpoints
          -->
        <v-col cols="5" md="12" class="d-flex align-center justify-end pt-0 pb-0">
          <!--
            Factory widget
            -->
          <section
            v-if="isMultipleFactories && !isAsideNavigationVisible && isLoggedIn"
            class="factory-selector wx-typo-sm mr-sm-2"
          >
            <v-menu
              v-if="isMobile"
              transition="slide-y-transition"
              nudge-bottom="8"
              class="factory-selector--mobile"
              bottom
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <wx-btn-icon
                  v-bind="attrs"
                  v-on="on"
                  :title="$t('mainHeader.applications')"
                  class="activator-btn mr-4 mr-sm-2"
                  icon
                  large
                >
                  <v-icon>$factoryIcon</v-icon>
                </wx-btn-icon>
              </template>
              <v-list class="factory-selector--mobile__list" nav>
                <v-list-item-group v-model="currentFactory" color="primary">
                  <v-list-item
                    v-for="(factory, index) in availableFactories"
                    :key="index"
                    :value="isCurrentFactory(factory)"
                    @click="onNewActiveFactory(factory)"
                    :class="
                      isCurrentFactory(factory) ? 'primary--text v-list-item--highlighted v-list-item--active' : null
                    "
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ factory.name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>

            <v-tooltip bottom v-if="isDisabled()">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <wx-autocomplete
                    v-model="currentFactory"
                    :items="availableFactories"
                    :disabled="isDisabled()"
                    :title="$t('mainHeader.factorySelection.dropdownHoverTitle')"
                    item-text="name"
                    item-value="id"
                    hide-details="true"
                    prepend-icon="$factoryIcon"
                    class="factory-dropdown filter-style"
                    validate-on-blur
                    return-object
                  />
                </div>
              </template>
              <span class="factory-disabled wx-typo-sm">
                {{ this.$t("mainHeader.factorySelection.disabledTooltip") }}
              </span>
            </v-tooltip>

            <wx-autocomplete
              v-if="!isMobile && !isDisabled()"
              v-model="currentFactory"
              @change="onNewActiveFactory"
              :items="availableFactories"
              :title="$t('mainHeader.factorySelection.dropdownHoverTitle')"
              :disabled="isOverviewLoading"
              item-text="name"
              item-value="id"
              hide-details="true"
              prepend-icon="$factoryIcon"
              class="factory-dropdown filter-style"
              validate-on-blur
              return-object
            />
          </section>
          <section
            v-if="!isMobile && !isMultipleFactories && !isAsideNavigationVisible && isLoggedIn"
            class="factory-selector wx-typo-sm mr-4"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <wx-autocomplete
                    v-model="currentFactory"
                    :items="availableFactories"
                    :disabled="true"
                    :title="$t('mainHeader.factorySelection.dropdownHoverTitle')"
                    item-text="name"
                    item-value="id"
                    hide-details="true"
                    prepend-icon="$factoryIcon"
                    class="factory-dropdown filter-style"
                    validate-on-blur
                    return-object
                  />
                </div>
              </template>
              <span class="factory-disabled wx-typo-sm">
                {{ this.$t("mainHeader.factorySelection.singleFactory") }}
              </span>
            </v-tooltip>
          </section>

          <apps-menu-window v-if="!isAsideNavigationVisible && isLoggedIn" />
          <notification-menu v-if="!isHomeUrl && !isAsideNavigationVisible && isLoggedIn" />
          <main-settings v-if="!isAsideNavigationVisible" />
        </v-col>
        <!--
          Left column
          -->
        <v-col v-if="isMobile" cols="7" class="d-flex align-center justify-start justify-sm-space-between pt-0 pb-0">
          <v-btn
            @click="$emit('toggleDrawerState')"
            :title="$t('mainHeader.menu')"
            class="nav-menu-btn ml-n1"
            icon
            large
          >
            <v-icon>{{ drawerMenuIcon }}</v-icon>
          </v-btn>
          <v-btn
            :to="getDestinationRoute"
            :title="$t('mainNav.logoAltText')"
            color="primary"
            class="wxapp-logo--mobile"
            exact
            plain
          />
        </v-col>
      </v-row>
    </v-app-bar>
  </fragment>
</template>

<script>
import MainSettings from "@/components/MainSettings.vue";
import NotificationMenu from "@/components/notification/NotificationMenu.vue";
import AppsMenuWindow from "@/components/AppsMenuWindow.vue";
import RouteService from "@/router/RouteService";
import WxAutocomplete from "@/components/ui/WxAutocomplete";
import WxBtnIcon from "@/components/ui/WxBtnIcon";
import { mapActions, mapGetters } from "vuex";
import { compareProductionUnitsByName } from "@/components/SortUtils";

export default {
  name: "MainHeader",
  components: {
    WxAutocomplete,
    MainSettings,
    NotificationMenu,
    AppsMenuWindow,
    WxBtnIcon,
  },
  props: {
    drawerState: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      currentFactory: null,
    };
  },
  computed: {
    ...mapGetters("user", ["factories", "isMultipleFactories", "isLoggedIn"]),
    ...mapGetters("navigation", ["activeFactory", "activeFactoryProductionUnits"]),
    ...mapGetters("overview", ["isOverviewLoading"]),
    getDestinationRoute() {
      return RouteService.toUserHome();
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    isHomeUrl() {
      return this.$route.path.includes("home");
    },
    isDashboardUrl() {
      return this.$route.path.includes("dashboard");
    },
    appBarColor() {
      return this.isMobile ? "var(--color-base-background)" : "transparent";
    },
    drawerMenuIcon() {
      return this.drawerState ? "mdi-close" : "mdi-menu";
    },
    availableFactories() {
      return this.factories.map((f) => ({ id: f.id, name: f.name }));
    },
    isAsideNavigationVisible() {
      return !!this.$route.meta.hideHeaderAsideNavigation;
    },
  },
  watch: {
    activeFactory() {
      this.init();
    },
  },
  methods: {
    ...mapActions("navigation", ["setActiveFactory"]),
    ...mapActions("dashboard", ["setActiveProductionUnitId"]),
    ...mapActions("overview", ["resetOverviewLoaders"]),
    init() {
      if (this.activeFactory) {
        this.currentFactory = {
          id: this.activeFactory.id,
          name: this.activeFactory.name,
        };
      }
    },
    isDisabled() {
      return this.$route.meta.disableFactorySelection;
    },
    getFactoryById(factoryId) {
      return this.factories.find((f) => f.id === factoryId);
    },
    onNewActiveFactory(selectedFactory) {
      const factory = this.getFactoryById(selectedFactory.id);
      this.resetOverviewLoaders();
      this.setActiveFactory(factory);

      if (this.activeFactoryProductionUnits && this.activeFactoryProductionUnits.length > 0) {
        const pu = this.activeFactoryProductionUnits.sort(compareProductionUnitsByName)[0];
        this.setActiveProductionUnitId(pu.id);
        this.init();
        if (this.isDashboardUrl) {
          this.$router.push(RouteService.toDashboard(pu.id));
        }
      }
    },

    isCurrentFactory(factory) {
      if (!this.currentFactory) return false;
      return this.currentFactory.id === factory.id;
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.wxapp-main-header {
  &.v-toolbar.v-app-bar {
    z-index: 8;
    box-shadow: var(--box-shadow-small-elevation);

    @media ($wx-md-min) {
      z-index: 1; // required for display hover wxapp-main-page
      left: auto !important; // flex aligned to right
      max-width: 320px; // constrain the width so wxapp-main-page content stays clickable
      border-bottom-left-radius: var(--border-radius-lg); // preventing design for z-index
      box-shadow: none;
    }
    @media ($wx-lg-min) {
      max-width: 375px; // allow factory-selector full width on large viewport
    }
    @media ($wx-xl-min) {
      margin-top: 10px !important; // vertically align with `wxapp-logo--drawer`
    }

    ::v-deep .v-toolbar__content {
      // constrain the height to ($vuetify.application.top value of) 56px
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

// gutter between right buttons
::v-deep .v-btn {
  margin-right: calc(var(--grid-gutter) / 2);

  &:last-child {
    margin-right: 0;
  }
}

.wxapp-logo--mobile.v-btn {
  // local vars
  $logo_iconSize: 34px;
  $logo_padding: 8px;
  // style
  height: ($logo_iconSize + $logo_padding);
  width: ($logo_iconSize + $logo_padding);
  min-width: $logo_iconSize;
  margin: 0 3em 0 0;
  padding: 0;
  border-radius: $logo_padding;
  background: url("~@/assets/tile-plus-logo-dark.svg") 60% center no-repeat; // T+ logo shape is not centered
  background-size: $logo_iconSize;

  &.theme--light {
    background-image: url("~@/assets/tile-plus-logo-light.svg");
  }
}

// Factories Dropdown List ----------------------------------↧↧↧ONLY_TilePlus
.factory-selector {
  margin-top: 2px; // vertical alignment with circular buttons on the right
  color: var(--color-text-subtle-theme);

  .factory-disabled {
    display: block;
    position: relative;
    z-index: 1;
    text-align: center;
  }

  .factory-dropdown {
    font-weight: 700; // bold

    .string {
      padding-left: 0.2rem;
      font-size: var(--font-size-sm);
      font-weight: 400;
      color: var(--color-text-subtle-theme);
    }

    ::v-deep .v-input {
      input {
        color: var(--color-text-subtle-theme);
      }
      &__icon {
        .v-icon {
          color: var(--color-text-subtle-theme);
        }
      }
    }
  }
}

.activator-btn {
  background-color: var(--color-control-background-theme);
}
// ---------------------------------------------------------↥↥↥ONLY_TilePlus
</style>
