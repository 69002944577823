import i18n from "@/i18n";
import NotificationService from "@/components/notification/NotificationService";

export default {
  namespaced: true,

  state: {
    notificationDialogState: false,
    notificationActiveIndex: 0,
    notificationItems: {
      en: [],
      fr: [],
    },
  },

  getters: {
    notificationDialogState(state) {
      return state.notificationDialogState;
    },
    notificationItems(state) {
      return i18n.locale === "en" ? state.notificationItems.en : state.notificationItems.fr;
    },
    notificationActive(state, getters) {
      if (getters.notificationItems.length > 0) {
        return getters.notificationItems[state.notificationActiveIndex];
      }
      return null;
    },
    notificationActiveIndex(state) {
      return state.notificationActiveIndex;
    },
    notificationQuantity(state, getters) {
      return getters.notificationItems.length;
    },
    notificationActiveIsFirst(state) {
      return state.notificationActiveIndex === 0;
    },
    notificationActiveIsLast(state, getters) {
      return state.notificationActiveIndex === getters.notificationQuantity - 1;
    },
  },

  actions: {
    fetchNotifications({ commit, rootGetters }) {
      if (rootGetters["user/isLoggedIn"]) {
        return NotificationService.getNotifications()
          .then((response) => {
            if (response.status === 200) {
              let backendNotifications = response.data;

              let englishNotifications = [];
              backendNotifications.map((n) => {
                englishNotifications.push({
                  date: n.date,
                  title: n.notification_translations.en.title,
                  paragraphs: n.notification_translations.en.message.split("\\n\\n"),
                });
              });
              let frenchNotifications = [];
              backendNotifications.map((n) => {
                frenchNotifications.push({
                  date: n.date,
                  title: n.notification_translations.fr.title,
                  paragraphs: n.notification_translations.fr.message.split("\\n\\n"),
                });
              });

              let notifications = {
                en: englishNotifications,
                fr: frenchNotifications,
              };
              commit("setNotifications", notifications);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    closeNotificationDialog({ commit }) {
      commit("closeNotificationDialog");
    },
    openNotificationDialog({ commit }, index) {
      commit("openNotificationDialog", index);
    },
    selectPreviousNotification({ getters, commit }) {
      if (!getters.notificationActiveIsFirst) {
        commit("selectPreviousNotification");
      }
    },
    selectNextNotification({ getters, commit }) {
      if (!getters.notificationActiveIsLast) {
        commit("selectNextNotification");
      }
    },
  },

  mutations: {
    setNotifications(state, notifications) {
      state.notificationItems = notifications;
    },
    closeNotificationDialog(state) {
      state.notificationDialogState = false;
    },
    openNotificationDialog(state, index) {
      state.notificationActiveIndex = index;
      state.notificationDialogState = true;
    },
    selectPreviousNotification(state) {
      state.notificationActiveIndex--;
    },
    selectNextNotification(state) {
      state.notificationActiveIndex++;
    },
  },
};
