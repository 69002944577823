import store from "../store";

class RouteService {
  toRoot() {
    return {
      name: "Root",
    };
  }
  toHome() {
    return {
      name: "Home",
    };
  }
  toDashboard(productionUnitId) {
    return {
      name: "Dashboard",
      params: {
        id: productionUnitId,
      },
    };
  }
  toOverview() {
    return {
      name: "Overview",
    };
  }
  toProductionDataExportRoute() {
    return {
      name: "ProductionDataExport",
    };
  }
  toUsers() {
    return {
      name: "Users",
    };
  }
  toUserCreation() {
    return {
      name: "UserCreation",
    };
  }
  toUserEdition(userId) {
    return {
      name: "UserEdition",
      params: {
        id: userId,
      },
    };
  }
  toProductSampling(productId) {
    return {
      name: "ProductSampling",
      params: {
        id: productId,
      },
    };
  }
  toDowntimeReasons(expandId) {
    let to = {
      name: "DowntimeReasons",
    };
    if (expandId) {
      to["query"] = { expand: expandId };
    }
    return to;
  }
  toDowntimeReasonsCreation(parentCategoryId) {
    let to = {
      name: "DowntimeReasonCreation",
    };
    if (parentCategoryId) {
      to["query"] = { parentCategoryId: parentCategoryId };
    }
    return to;
  }
  toDowntimeReasonEdition(reasonId) {
    let to = {
      name: "DowntimeReasonEdition",
    };
    if (reasonId) {
      to["query"] = { reasonId: reasonId };
    }
    return to;
  }
  toDowntimeCategoryCreation(parentCategoryId) {
    let to = {
      name: "DowntimeCategoryCreation",
    };
    if (parentCategoryId) {
      to["query"] = { parentCategoryId: parentCategoryId };
    }
    return to;
  }
  toDowntimeCategoryEdition(categoryId) {
    let to = {
      name: "DowntimeCategoryEdition",
    };
    if (categoryId) {
      to["query"] = { categoryId: categoryId };
    }
    return to;
  }
  toRejectReasons(expandId) {
    let to = {
      name: "RejectReasons",
    };
    if (expandId) {
      to["query"] = { expand: expandId };
    }
    return to;
  }
  toRejectReasonsCreation(parentCategoryId) {
    let to = {
      name: "RejectReasonCreation",
    };
    if (parentCategoryId) {
      to["query"] = { parentCategoryId: parentCategoryId };
    }
    return to;
  }
  toRejectReasonEdition(reasonId) {
    let to = {
      name: "RejectReasonEdition",
    };
    if (reasonId) {
      to["query"] = { reasonId: reasonId };
    }
    return to;
  }
  toRejectCategoryCreation(parentCategoryId) {
    let to = {
      name: "RejectCategoryCreation",
    };
    if (parentCategoryId) {
      to["query"] = { parentCategoryId: parentCategoryId };
    }
    return to;
  }
  toRejectCategoryEdition(categoryId) {
    let to = {
      name: "RejectCategoryEdition",
    };
    if (categoryId) {
      to["query"] = { categoryId: categoryId };
    }
    return to;
  }
  toProducts() {
    return {
      name: "Products",
    };
  }
  toProductCreation() {
    return {
      name: "ProductCreation",
    };
  }
  toProductEdition(productId) {
    return {
      name: "ProductEdition",
      params: {
        id: productId,
      },
    };
  }
  toProductionUnits() {
    return {
      name: "ProductionUnits",
    };
  }
  toProductionUnitEdition(productionUnitId, productionUnitName) {
    return {
      name: "ProductionUnitEdition",
      params: {
        id: productionUnitId,
        name: productionUnitName
      },
    };
  }
  toProductionUnitMeasurementActivity(productionUnitId) {
    return {
      name: "ProductionUnitMeasurementActivity",
      params: {
        id: productionUnitId,
      },
    };
  }
  toShiftSchedules() {
    return {
      name: "ShiftSchedules",
    };
  }
  toShiftCreation() {
    return {
      name: "ShiftCreation",
    };
  }
  toShiftEdition(workShiftId) {
    return {
      name: "ShiftEdition",
      params: {
        id: workShiftId,
      },
    };
  }
  toDevices() {
    return {
      name: "Devices",
    };
  }
  toDeviceStatus(deviceId) {
    return {
      name: "DeviceStatus",
      params: {
        id: deviceId,
      },
    };
  }
  toTermsOfService() {
    return {
      name: "TermsOfService",
    };
  }
  toStyleGuide() {
    return {
      name: "Styleguide",
    };
  }
  toUserHome() {
    if (store.getters["user/isOperator"]) {
      return this.toRoot();
    } else {
      return this.toOverview();
    }
  }
  toAlerts() {
    return {
      name: "Alerts",
    };
  }
  toAlert(alertId) {
    return {
      name: "Alert",
      params: {
        id: alertId,
      },
    };
  }
  toCreateAlert() {
    return {
      name: "CreateAlert",
    };
  }
}

export default new RouteService();
