import http from "../../BaseServiceHttp";
import { DateTime, Duration } from "luxon";

const dateFormat = "yyyy-MM-dd'T'00:00:00ZZ";

class NotificationService {
  getNotifications() {
    const cutoffDate = DateTime.now()
      .minus(Duration.fromObject({ days: 14 }))
      .toFormat(dateFormat);
    return http.get(`/notifications?cutoff=` + cutoffDate);
  }
}

export default new NotificationService();
