import { DateTime } from "luxon";
import TimeRangeService from "@/store/TimeRangeService";

export const DATE_FORMAT = "yyyy-LL-dd";
export const DATE_TIME_FORMAT = "yyyy-LL-dd HH:mm:ss ZZ";
export const DATE_TIME_FORMAT_UTC = "yyyy-LL-ddTHH:mm:ssZ";
export const FULL_DATE_TIME_FORMAT = "yyyy-LL-dd HH:mm:ss.SSS z";
export const DATE_TIME_FORMAT_NO_TIMEZONE = "yyyy-LL-dd HH:mm:ss";
export const DATE_TIME_FORMAT_NO_SECONDS = "yyyy-LL-dd HH:mm";
export const TIME_FORMAT = "HH:mm";
export const HOUR = "hour";
export const SHIFT = "shift";
export const PRODUCTION_DAY = "production_day";
export const PRODUCTION_RUN = "production_run";
export const HOURS_IN_MILLIS__24 = 24 * 60 * 60 * 1000;
export const HOURS_IN_MILLIS__1 = 60 * 60 * 1000;
export const DAYS_IN_MILLIS__3 = 3 * 24 * 60 * 60 * 1000;

export function toEpochMillisUTC(date) {
  if (!date) return 0;
  return DateTime.fromISO(date, { zone: "UTC" }).toMillis();
}

export function fromEpochMillis(millis, timezone, optionalFormat) {
  const format = optionalFormat ? optionalFormat : FULL_DATE_TIME_FORMAT;
  return DateTime.fromMillis(millis)
    .setZone(timezone)
    .toFormat(format);
}

export function getBusinessDate(timezone, productionDayMinutesFromMidnight, isCalendarDayBusinessDay) {
  const now = DateTime.now().setZone(timezone);
  return getBusinessDateTime(now, productionDayMinutesFromMidnight, isCalendarDayBusinessDay).toFormat(DATE_FORMAT);
}

export function getBusinessDateTime(dateTimeWithTimeZone, productionDayMinutesFromMidnight, isCalendarDayBusinessDay) {
  let now = dateTimeWithTimeZone;
  let midnight = now.startOf("day");
  let minutesAfterMidnight = (now.toMillis() - midnight.toMillis()) / (60 * 1000);
  if (isCalendarDayBusinessDay && minutesAfterMidnight < productionDayMinutesFromMidnight) {
    return now.minus({ days: 1 });
  }
  if (!isCalendarDayBusinessDay && minutesAfterMidnight >= productionDayMinutesFromMidnight) {
    return now.plus({ days: 1 });
  } else {
    return now;
  }
}

export function getStartBusinessDateTime(
  dateTimeWithTimeZone,
  productionDayMinutesFromMidnight,
  isCalendarDayBusinessDay,
) {
  const calendarDay = isCalendarDayBusinessDay ? dateTimeWithTimeZone : dateTimeWithTimeZone.minus({ days: 1 });
  const hours = parseInt(productionDayMinutesFromMidnight / 60);
  const minutes = parseInt(productionDayMinutesFromMidnight % 60);
  return calendarDay.set({ hour: hours, minute: minutes, millisecond: 0 });
}

export function getTodayDateTime(timezone) {
  let d;
  if (timezone) {
    d = DateTime.now().setZone(timezone);
  } else {
    d = DateTime.now();
  }
  return d.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
}

export function getTimeFromMillis(millisUtc, timezone) {
  let dateTime = DateTime.fromMillis(millisUtc, { zone: timezone });
  return dateTime.toFormat(TIME_FORMAT);
}

export function getCurrentTime(timezone) {
  let d;
  if (timezone) {
    d = DateTime.now().setZone(timezone);
  } else {
    d = DateTime.now();
  }
  return d.toFormat(TIME_FORMAT);
}

export function getDateFromMillis(millisUtc, timezone) {
  let dateTime = DateTime.fromMillis(millisUtc, { zone: timezone });
  return dateTime.toFormat(DATE_FORMAT);
}

export function getTodayDate(timezone) {
  return getTodayDateTime(timezone).toFormat(DATE_FORMAT);
}

export function dayStartEndTimesUTCWithTime(day, time, factoryTimezone) {
  const stringToDate = DateTime.fromFormat(day, DATE_FORMAT);
  const year = stringToDate.year;
  const month = stringToDate.month;
  const dayOfMonth = stringToDate.day;
  const startDateUTC = DateTime.now()
    .setZone(factoryTimezone)
    .set({ year: year, month: month, day: dayOfMonth, hour: parseInt(time.slice(0,2)), minute: 0, second: 0, millisecond: 0 })
    .toUTC();
  const endDateUTC = startDateUTC.plus({ hour: 1 });
  const startDateISO = startDateUTC.toISO();
  const endDateISO = endDateUTC.toISO();
  return {
    startDateISO: startDateISO,
    endDateISO: endDateISO,
  };

}

export function toTimeHHMM(millis, timezone) {
  return millis
    ? DateTime.fromMillis(millis)
        .setZone(timezone)
        .toFormat(TIME_FORMAT)
    : "";
}

export function toDateTimeNoTimezone(millis, timezone) {
  return millis
    ? DateTime.fromMillis(millis)
        .setZone(timezone)
        .toFormat(DATE_TIME_FORMAT_NO_TIMEZONE)
    : "";
}

export function getNumberOfDaysInLastNMonths(n) {
  const now = DateTime.now();
  const last15Months = now.minus({ months: n });
  const difference = now.ts - last15Months.ts;
  return parseInt(difference / (1000 * 60 * 60 * 24));
}

/**
 *
 * @param dateTimeWithTimeZone
 * @param hour
 * @returns {{start: DateTime, end: DateTime}}
 */
export function getHourTimeRange(dateTimeWithTimeZone, hour) {
  const start = dateTimeWithTimeZone.set({ hour: hour, minute: 0, second: 0, millisecond: 0 });
  const end = start.plus({ hours: 1 });
  return { start, end };
}

/**
 *
 * @param workShift
 * @param timeZone
 * @returns {{start: DateTime, end: DateTime}}
 */
export function getWorkShiftTimeRange(workShift) {
  const start = DateTime.fromISO(workShift.start_date);
  const end = workShift.end_date ? DateTime.fromISO(workShift.end_date) : null;
  return { start, end };
}

/**
 *
 * @param productionRun
 * @param timeZone
 * @returns {{start: DateTime, end: DateTime}}
 */
export function getProductionRunTimeRange(productionRun) {
  const start = DateTime.fromISO(productionRun.start_date);
  const end = productionRun.end_date ? DateTime.fromISO(productionRun.end_date) : null;
  return { start, end };
}

/**
 *
 * @param date
 * @param productionUnitId
 * @returns {Promise<{start: DateTime, end: DateTime}>}
 */
export async function getProductionDayTimeRange(date, productionUnitId, factoryTimezone) {
  const response = await TimeRangeService.getTimeRangeForDate(productionUnitId, date).catch((error) =>
    console.log(error),
  );
  const start = DateTime.fromISO(response.data.start_date).setZone(factoryTimezone);
  const end = DateTime.fromISO(response.data.end_date).setZone(factoryTimezone);
  return { start, end };
}

export function getQuantityGraphTimeAggregation(durationInMillis) {
  if (durationInMillis > HOURS_IN_MILLIS__24) return "24h";
  if (durationInMillis > HOURS_IN_MILLIS__1) return "60m";
  return "15m";
}

export function isDurationGreaterThanNDays(durationInMillis, days) {
  return durationInMillis > days * HOURS_IN_MILLIS__24;
}
