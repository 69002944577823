import Vue from "vue";
import Vuex from "vuex";
import dashboard from "./modules/dashboard";
import user from "./modules/user";
import operation from "./modules/operation";
import notifications from "./modules/notifications";
import overview from "./modules/overview";
import packages from "./modules/packages";
import navigation from "@/store/modules/navigation";
import productAdmin from "@/store/modules/productAdmin";
import devicesAdmin from "@/store/modules/devicesAdmin";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    dashboard,
    user,
    operation,
    notifications,
    packages,
    overview,
    navigation,
    productAdmin,
    devicesAdmin,
  },
});
