import helpers from "@/helpers";

export function toProductionUnitOverview(productionUnitMetrics, productionUnitsState, productionUnits) {
  const productionUnitId = productionUnitMetrics.key.production_unit_id;
  const productionUnit = productionUnits.find((pu) => pu.id === productionUnitId);
  const stateForPU = productionUnitsState.find((state) => state.puId === productionUnitId);
  const currentSku = stateForPU.productSku ? stateForPU.productSku : "";

  const allProductQuantityValue = helpers.round(productionUnitMetrics.metrics.produced_quantity.total_count, 0);
  const currentProductQuantityValue = helpers.round(
    productionUnitMetrics.metrics.produced_quantity.quantity_by_sku[currentSku]?.count,
    0,
  );
  const ratePerHourValue = helpers.round(
    productionUnitMetrics.metrics.produced_quantity.quantity_by_sku[currentSku]?.count_throughput,
    1,
  );
  const ratePerHourTargetValue = helpers.round(
    productionUnitMetrics.metrics.performance.performance_by_sku[currentSku]?.production_objective,
    1,
  );
  const ratePerMinuteValue = ratePerHourValue ? helpers.round(ratePerHourValue / 60.0, 1) : null;
  const ratePerMinuteTargetValue = helpers.round(
    productionUnitMetrics.metrics.performance.performance_by_sku[currentSku]?.production_objective / 60.0,
    1,
  );
  const giveawayQuantityInStandardUnit = helpers.round(
    productionUnitMetrics.metrics.giveaway.total_giveaway_quantity,
    1,
  );
  const giveawayValue = helpers.round(productionUnitMetrics.metrics.giveaway.total_giveaway_percentage, 1);
  const currentProductGiveawayQuantityInStandardUnit = helpers.round(
    productionUnitMetrics.metrics.giveaway.giveaway_by_sku[currentSku]?.giveaway_quantity,
    1,
  );
  const currentProductGiveawayQuantityInUnit = helpers.round(
    productionUnitMetrics.metrics.giveaway.giveaway_by_sku[currentSku]?.giveaway_quantity_in_unit,
    1,
  );
  const currentProductGiveawayValue = helpers.round(
    productionUnitMetrics.metrics.giveaway.giveaway_by_sku[currentSku]?.giveaway_percentage,
    1,
  );

  return {
    puId: productionUnitId,
    puName: productionUnit.name,
    puStatus: stateForPU.puStatus,
    productSku: currentSku,
    productName: stateForPU.productName,
    availabilityValue: helpers.round(productionUnitMetrics.metrics.availability.value, 1),
    availabilityTarget: productionUnit.availabilityTarget,
    performanceValue: helpers.round(productionUnitMetrics.metrics.performance.value, 1),
    performanceTarget: productionUnit.performanceTarget,
    qualityValue: helpers.round(productionUnitMetrics.metrics.quality.value, 1),
    qualityTarget: productionUnit.qualityTarget,
    oeeValue: helpers.round(productionUnitMetrics.metrics.oee, 1),
    oeeTarget: productionUnit.oeeTarget,
    ooeValue: helpers.round(productionUnitMetrics.metrics.ooe, 1),
    allProductQuantityValue: allProductQuantityValue,
    currentProductQuantityValue: currentProductQuantityValue,
    ratePerHourValue: ratePerHourValue,
    ratePerHourTargetValue: ratePerHourTargetValue,
    ratePerMinuteValue: ratePerMinuteValue,
    ratePerMinuteTargetValue: ratePerMinuteTargetValue,
    giveawayQuantityInStandardUnit: giveawayQuantityInStandardUnit,
    giveawayValue: giveawayValue,
    currentProductGiveawayQuantityInStandardUnit: currentProductGiveawayQuantityInStandardUnit,
    currentProductGiveawayQuantityInUnit: currentProductGiveawayQuantityInUnit,
    currentProductGiveawayValue: currentProductGiveawayValue,
  };
}
