import http from "../../BaseServiceHttp";
import store from "../../store";
import Tiles, { inProgressDowntimeTileName, tileKeyByName } from "@/components/Tiles";
import i18n from "@/i18n";
import { DIRECTOR_ROLE, PRESENTER_ROLE, SUPERVISOR_ROLE } from "@/store/modules/user";

class UserService {
  getUsers(factoryId) {
    return http.get(`factories/${factoryId}/users`);
  }
  getUser(userId) {
    return http.get(`/users/${userId}`);
  }
  getUserPreferences() {
    return http.get(`/user/preferences`);
  }
  createUser(newUser) {
    return http.post(`/users`, newUser);
  }
  inviteUser(userInviteRequest) {
    return http.post(`/users/invite`, userInviteRequest);
  }
  updateUser(userId, userUpdateRequest) {
    return http.put(`/users/${userId}`, userUpdateRequest);
  }
  deleteUser(userId) {
    return http.delete(`/users/${userId}`);
  }
  getAccount(accountId) {
    return http.get(`/accounts/${accountId}`);
  }

  getAvatarText(displayName) {
    if (displayName && displayName.length > 0) {
      let parts = displayName
        .split(/(\s+)/)
        .map((p) => p.trim())
        .filter((p) => p.length > 0);

      if (parts.length === 1) {
        return parts[0].charAt(0).toUpperCase();
      }
      if (parts.length >= 2) {
        return parts
          .map((p) => p.charAt(0).toUpperCase())
          .slice(0, 2)
          .join("");
      }
    }
    return "";
  }
  getAvailableRoles() {
    return [
      { value: PRESENTER_ROLE, text: i18n.t("user.roles.presentation") },
      { value: SUPERVISOR_ROLE, text: i18n.t("user.roles.operation") },
      { value: DIRECTOR_ROLE, text: i18n.t("user.roles.management") },
    ];
  }
  getOperationRoleOption() {
    return { value: SUPERVISOR_ROLE, text: i18n.t("user.roles.operation") };
  }
  isWorximityUser(user) {
    return user.email.startsWith("wxadmin") || user.email.startsWith("wxsysadmin");
  }
  isPresentor(role) {
    return role === PRESENTER_ROLE;
  }
  isOperation(role) {
    return role === SUPERVISOR_ROLE;
  }
  isManagement(role) {
    return role === DIRECTOR_ROLE;
  }
  getDisplayRoleNameForValue(roleValue) {
    let availableRole = this.getAvailableRoles().find((r) => r.value === roleValue);
    return availableRole?.text ?? i18n.t("user.roles.invalid");
  }

  buildTileSelection(tiles) {
    const sortedTiles = tiles.sort((t1, t2) => (t1.position > t2.position ? 1 : t2.position > t1.position ? -1 : 0));
    return sortedTiles.map((tileSlot) => this.getTileName(tileSlot)).filter((name) => name !== null);
  }

  getTileName(tileSlot) {
    if (tileSlot.availability) {
      return Tiles.availability.name;
    }
    if (tileSlot.performance) {
      return Tiles.performance.name;
    }
    if (tileSlot.oee) {
      return Tiles.oee.name;
    }
    if (tileSlot.product_quantity) {
      if (tileSlot.product_quantity.scope && tileSlot.product_quantity.scope === "current_product") {
        return Tiles.currentProductQuantity.name;
      } else {
        return Tiles.allProductsQuantity.name;
      }
    }
    if (tileSlot.product_throughput) {
      if (tileSlot.product_throughput.unit && tileSlot.product_throughput.unit === "units_per_minute") {
        return Tiles.currentProductThroughputPerMinute.name;
      } else {
        return Tiles.currentProductThroughput.name;
      }
    }
    if (tileSlot.product_speed_5m) {
      if (tileSlot.product_speed_5m.unit && tileSlot.product_speed_5m.unit === "units_per_minute") {
        return Tiles.currentProductSpeed5mPerMinute.name;
      } else {
        return Tiles.currentProductSpeed5m.name;
      }
    }
    if (tileSlot.total_uptime) {
      return Tiles.totalUptime.name;
    }
    if (tileSlot.total_downtime) {
      return Tiles.totalDowntime.name;
    }
    if (tileSlot.product_giveaway) {
      return Tiles.productGiveaway.name;
    }
    if (tileSlot.product_giveaway_percent) {
      return Tiles.productGiveawayPercent.name;
    }
    if (tileSlot.product_average_giveaway) {
      return Tiles.productAverageGiveaway.name;
    }
    if (tileSlot.in_progress_downtime) {
      return inProgressDowntimeTileName;
    }
    if (tileSlot.work_order) {
      return Tiles.workOrder.name;
    }
    if (tileSlot.lot) {
      return Tiles.lot.name;
    }
    if (tileSlot.production) {
      return Tiles.production.name;
    }
    if (tileSlot.quality) {
      return Tiles.quality.name;
    }
    if (tileSlot.reject_quantity) {
      return Tiles.rejectQuantity.name;
    }
    if (tileSlot.cycle_time) {
      return Tiles.cycleTime.name;
    }
    if (tileSlot.cycle_quantity) {
      return Tiles.cycleQuantity.name;
    }
    if (tileSlot.ooe) {
      return Tiles.ooe.name;
    }
    return null;
  }

  updatePreferences(newPreferences) {
    return http.put(`/user/preferences`, newPreferences);
  }

  updateActiveProductionUnitTileSelection() {
    const activeProductionUnitId = store.getters["dashboard/activeProductionUnitId"];
    const tileSelection = store.getters["dashboard/tileSelection"];
    let userPreferences = store.getters["user/preferences"];

    let newTiles = this.buildTiles(tileSelection);
    const productionUnitDashboardPreferences = userPreferences.dashboards.production_units.find(
      (pu) => pu.id === activeProductionUnitId,
    );
    if (productionUnitDashboardPreferences) {
      productionUnitDashboardPreferences.tiles = newTiles;
    }
    return userPreferences;
  }

  buildTiles(tileSelection) {
    let newTileSelection = [];

    for (let i = 0; i < tileSelection.length; i++) {
      let tileSelectionElement = tileSelection[i];
      let tileKeyByNameElement = tileKeyByName[tileSelectionElement];
      let tileElement = { position: i + 1 };
      tileElement[tileKeyByNameElement] = {};
      const tile = this.addTileConfig(tileElement, tileSelectionElement);
      newTileSelection.push(tile);
    }
    return newTileSelection;
  }

  updateActiveProductionUnitGraphSelection(newSelectedGraph) {
    const activeProductionUnitId = store.getters["dashboard/activeProductionUnitId"];
    let userPreferences = store.getters["user/preferences"];

    const productionUnitDashboardPreferences = userPreferences.dashboards.production_units.find(
      (pu) => pu.id === activeProductionUnitId,
    );
    if (productionUnitDashboardPreferences) {
      productionUnitDashboardPreferences.graph = newSelectedGraph;
    }

    return userPreferences;
  }

  addTileConfig(tileElement, tileSelectionElement) {
    let tileKeyByNameElement = tileKeyByName[tileSelectionElement];
    switch (tileKeyByNameElement) {
      case "current_product_quantity": {
        return {
          position: tileElement.position,
          product_quantity: {
            scope: "current_product",
          },
        };
      }
      case "product_quantity": {
        return {
          position: tileElement.position,
          product_quantity: {
            scope: "all_products",
          },
        };
      }
      case "product_throughput": {
        return {
          position: tileElement.position,
          product_throughput: {
            unit: "units_per_hour",
          },
        };
      }
      case "product_throughput_per_minute": {
        return {
          position: tileElement.position,
          product_throughput: {
            unit: "units_per_minute",
          },
        };
      }
      case "product_speed_5m": {
        return {
          position: tileElement.position,
          product_speed_5m: {
            unit: "units_per_hour",
          },
        };
      }
      case "product_speed_5m_per_minute": {
        return {
          position: tileElement.position,
          product_speed_5m: {
            unit: "units_per_minute",
          },
        };
      }
      default:
        return tileElement;
    }
  }
}

export default new UserService();
