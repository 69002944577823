import FactoryService from "@/components/factory/FactoryService";
import ErrorHandling from "@/components/ErrorHandling";
import i18n from "@/i18n";
import UserService from "@/components/user/UserService";
import DemoService from "@/components/DemoService";

import { version } from "@/../package.json";

export const OPERATOR_ROLE = "operator";
export const SUPERVISOR_ROLE = "supervisor";
export const DIRECTOR_ROLE = "director";
export const PRESENTER_ROLE = "presenter";

export default {
  namespaced: true,

  state: {
    zToken: null,
    uToken: null,
    accountId: "",
    userId: "",
    email: "",
    username: "",
    role: "",
    isAdmin: false,
    isFinancialAdmin: false,
    isTilelyticsEnabled: false,
    presenterKey: "",

    account: null,

    factories: [],

    language: "en",
    theme: "dark",
    preferences: null,

    applicationVersion: version,
    worximityAdminAllowedCoverageInMonths: 6,
  },

  getters: {
    zToken(state) {
      return state.zToken;
    },
    uToken(state) {
      return state.uToken;
    },
    applicationVersion(state) {
      return state.applicationVersion;
    },
    accountId(state) {
      return state.accountId;
    },
    userId(state) {
      return state.userId;
    },
    email(state) {
      return state.email;
    },
    username(state) {
      return state.username;
    },
    isAdmin(state) {
      return state.isAdmin;
    },
    isLoggedInUserFinancialAdmin(state) {
      return state.isFinancialAdmin;
    },
    isLoggedIn(state) {
      return state.userId !== "";
    },
    loggedInUserRole(state) {
      return state.role;
    },
    isOperator(state) {
      return state.role === OPERATOR_ROLE;
    },
    isSupervisor(state) {
      return state.role === SUPERVISOR_ROLE;
    },
    isDirector(state) {
      return state.role === DIRECTOR_ROLE;
    },
    isPresenter(state) {
      return state.role === PRESENTER_ROLE;
    },
    isTilelyticsEnabled(state) {
      return state.isTilelyticsEnabled;
    },
    hasOverviewAccess(state) {
      return state.role === PRESENTER_ROLE || state.role === SUPERVISOR_ROLE || state.role === DIRECTOR_ROLE;
    },
    isWorximityAdmin(state) {
      return String(state.email)
        .toLowerCase()
        .startsWith("wxadmin@");
    },
    worximityAdminAllowedCoverageInMonths(state) {
      return state.worximityAdminAllowedCoverageInMonths;
    },
    factories(state) {
      return state.factories;
    },
    isMultipleFactories(state) {
      return state.factories && state.factories.length > 1;
    },
    language(state) {
      return state.language;
    },
    isSpanish(state) {
      return state.language === "es";
    },
    theme(state) {
      return state.theme;
    },
    preferences(state) {
      return state.preferences;
    },
    availableProductionUnits(state) {
      let productionUnits = [];
      state.factories.forEach((factory) => {
        const puWithFactoryName = factory.productionUnits.map((pu) => ({
          id: pu.id,
          name: pu.name,
          factoryId: factory.id,
          factory: factory.name,
          packages: pu.packages,
        }));
        productionUnits.push(...puWithFactoryName);
      });
      return productionUnits;
    },
    presenterKey(state) {
      return state.presenterKey;
    },
    account(state) {
      return state.account;
    },
  },

  actions: {
    fetchUserAccount({ commit }, accountId) {
      if (!accountId || accountId === "") return;
      return UserService.getAccount(accountId)
        .then((response) => {
          if (response.status === 200) {
            const account = response.data;
            commit("setAccount", account);
          }
        })
        .catch((error) =>
          commit(
            "operation/showOperationError",
            ErrorHandling.buildErrorsMessages(error.response, (code) =>
              i18n.t("common.errors.default", { code: code }),
            ),
            { root: true },
          ),
        );
    },
    fetchUserFactories({ commit, getters }) {
      function getObjective(objectives, objectiveType) {
        return objectives.find((o) => o.type === objectiveType);
      }

      return FactoryService.getFactoriesForUser()
        .then((response) => {
          if (response.status === 200) {
            const factories = response.data.map((f, index) => ({
              id: f.id,
              name: DemoService.maskFactoryNameIfNecessary(getters.email, f.name, index + 1),
              timezone: f.timezone,
              primaryLanguage: f.primary_language,
              yearFirstMonth: f.year_first_month ? f.year_first_month : "january",
              weekFirstDay: f.week_first_day ? f.week_first_day : "monday",
              currency: f.currency ? f.currency : "CAD",
              productionUnits: f.production_units.map((pu, puIndex) => ({
                id: pu.id,
                name: DemoService.maskProductionUnitNameIfNecessary(getters.email, pu.name, puIndex + 1),
                downtimeJustificationDelay: pu.downtime_justification_delay_in_seconds,
                convertedUnitName: pu.converted_unit_name,
                packages: pu.packages,
                availabilityTarget: getObjective(pu.objectives, "availability")?.value,
                performanceTarget: getObjective(pu.objectives, "performance")?.value,
                qualityTarget: getObjective(pu.objectives, "quality")?.value,
                oeeTarget: getObjective(pu.objectives, "oee")?.value,
              })),
              factoryObjectives: f.objectives.map((obj) => ({
                value: obj.value,
                objective: obj.objective_type,
              })),
              productionDayMinutesFromMidnight: f.production_day_minutes_from_midnight,
              isCalendarDayBusinessDay: f.is_calendar_day_business_day,
            }));
            commit("setFactories", factories);
          }
        })
        .catch((error) =>
          commit(
            "operation/showOperationError",
            ErrorHandling.buildErrorsMessages(error.response, (code) =>
              i18n.t("common.errors.default", { code: code }),
            ),
            { root: true },
          ),
        );
    },
    updateLanguage({ commit, getters }, newLanguage) {
      if (getters.userId) {
        UserService.getUserPreferences()
          .then((response) => {
            if (response.status === 200) {
              let preferencesDB = response.data;
              let preferences = getters.preferences;
              preferences.language = newLanguage;
              preferencesDB.language = newLanguage;
              commit("setPreferences", preferences);
              UserService.updatePreferences(preferencesDB);
            }
          })
          .catch((error) => {
            commit(
              "operation/showOperationError",
              ErrorHandling.buildErrorsMessages(error.response, () => i18n.t("common.errors.loadPreferences")),
              { root: true },
            );
          });
      }
    },
    updateTheme({ commit, getters }, newTheme) {
      if (getters.userId) {
        UserService.getUserPreferences()
          .then((response) => {
            if (response.status === 200) {
              let preferencesDB = response.data;
              let preferences = getters.preferences;
              preferences.theme = newTheme;
              preferencesDB.theme = newTheme;
              commit("setPreferences", preferences);
              UserService.updatePreferences(preferencesDB);
            }
          })
          .catch((error) => {
            commit(
              "operation/showOperationError",
              ErrorHandling.buildErrorsMessages(error.response, () => i18n.t("common.errors.loadPreferences")),
              { root: true },
            );
          });
      }
    },
    updateSelectedProductionUnitId({ commit, getters }, newSelectedProductionUnitId) {
      if (getters.userId) {
        UserService.getUserPreferences()
          .then((response) => {
            if (response.status === 200) {
              let preferencesDB = response.data;
              let preferences = getters.preferences;
              preferences.dashboards.selected_production_unit_id = newSelectedProductionUnitId;
              preferencesDB.dashboards.selected_production_unit_id = newSelectedProductionUnitId;
              commit("setPreferences", preferences);
              UserService.updatePreferences(preferencesDB);
            }
          })
          .catch((error) => {
            commit(
              "operation/showOperationError",
              ErrorHandling.buildErrorsMessages(error.response, () => i18n.t("common.errors.loadPreferences")),
              { root: true },
            );
          });
      }
    },
    updateActiveProductionUnitTileSelection({ commit, getters }) {
      if (getters.userId) {
        UserService.getUserPreferences()
          .then((response) => {
            if (response.status === 200) {
              let preferencesDB = response.data;
              const updatedPreferences = UserService.updateActiveProductionUnitTileSelection();
              preferencesDB.dashboards = JSON.parse(JSON.stringify(updatedPreferences)).dashboards;
              commit("setPreferences", updatedPreferences);
              UserService.updatePreferences(preferencesDB);
            }
          })
          .catch((error) => {
            commit(
              "operation/showOperationError",
              ErrorHandling.buildErrorsMessages(error.response, () => i18n.t("common.errors.loadPreferences")),
              { root: true },
            );
          });
      }
    },
    updateSelectedGraph({ commit, getters }, newSelectedGraph) {
      if (getters.userId) {
        UserService.getUserPreferences()
          .then((response) => {
            if (response.status === 200) {
              let preferencesDB = response.data;
              const updatedPreferences = UserService.updateActiveProductionUnitGraphSelection(newSelectedGraph);
              preferencesDB.dashboards = JSON.parse(JSON.stringify(updatedPreferences)).dashboards;
              commit("setPreferences", updatedPreferences);
              UserService.updatePreferences(preferencesDB);
            }
          })
          .catch((error) => {
            commit(
              "operation/showOperationError",
              ErrorHandling.buildErrorsMessages(error.response, () => i18n.t("common.errors.loadPreferences")),
              { root: true },
            );
          });
      }
    },
    updateTilelyticsEnabled({ commit }, isEnabled) {
      commit("setTilelyticsEnabled", isEnabled);
    },
    setZToken({ commit }, zToken) {
      commit("setZToken", zToken);
    },
  },

  mutations: {
    setUser(state, user) {
      state.userId = user.userId;
      state.email = user.email;
      state.username = user.username;
      state.role = user.role;
      state.isAdmin = user.isAdmin;
      state.isFinancialAdmin = user.isFinancialAdmin;
      state.accountId = user.accountId;
      state.presenterKey = user.presenterKey;
    },
    clearUser(state) {
      state.userId = "";
      state.email = "";
      state.username = "";
      state.role = "";
      state.isAdmin = false;
      state.isFinancialAdmin = false;
      state.factories = [];
      state.accountId = "";
      state.presenterKey = "";
    },
    setFactories(state, factories) {
      state.factories = factories;
    },
    setPreferences(state, preferences) {
      state.preferences = preferences;
      state.language = preferences.language;
      state.theme = preferences.theme;
    },
    setTilelyticsEnabled(state, isEnabled) {
      state.isTilelyticsEnabled = isEnabled;
    },
    setAccount(state, account) {
      state.account = account;
    },
    setZToken(state, zToken) {
      state.zToken = zToken;
    },
    setUToken(state, uToken) {
      state.uToken = uToken;
    },
  },
};
