import TimelineService from "@/components/dashboard/TimelineService";
import DowntimeJustificationService from "@/components/dashboard/DowntimeJustificationService";
import ProductService from "@/components/product/ProductService";
import ProductionRunService from "@/components/dashboard/productionRun/ProductionRunService";
import TileService from "@/components/dashboard/tiles/TileService";
import ErrorHandling from "@/components/ErrorHandling";
import i18n from "@/i18n";
import GraphService from "@/components/dashboard/GraphService";
import Vue from "vue";
import UserService from "@/components/user/UserService";
import Tiles from "@/components/Tiles";
import ProductionUnitService from "@/components/productionunit/ProductionUnitService";
import Helpers from "@/helpers";
import ProductObjectives from "@/components/product/ProductObjectives";
import * as TimeUtils from "@/store/TimeUtils";
import * as SortUtils from "@/components/SortUtils";
import RejectReasonService from "@/components/rejectreason/RejectReasonService";
import RejectService from "@/components/dashboard/RejectService";
import PackageFeatures from "@/components/PackageFeatures";
import convert from "convert-units";
import { isDurationGreaterThanNDays, getQuantityGraphTimeAggregation } from "@/store/TimeUtils";

export default {
  namespaced: true,

  state: {
    activeProductionUnitId: "",

    tileSelection: [],
    giveawayViewToggle: false,
    samplingSessionInProgress: null,
    factoryProducts: [],
    startingSku: null,
    currentProduct: null,
    currentProductionRun: null,
    currentWorkOrder: null,
    currentLot: null,
    currentGiveawayProductSku: null,
    currentProductPUAssociations: [],
    cycleStats: [],
    timeline: {
      offsetFromMidnight: 0,
      coverageProgress: 0,
      coverageDuration: 0,
      blocks: [],
      markers: [],
    },
    oeeGraph: {
      coverageDuration: 0,
      interval: 0,
      offset: 0,
      target: 0,
      labels: [],
      curveData: [],
      oeeSources: [],
    },

    quantityGraph: {
      labels: [],
      quantityData: [],
    },

    giveawayGraph: null,
    currentDowntimeDuration: 0,
    selectedDowntimeId: null,
    availableDowntimeReasons: [],

    availableRejectReasons: [],

    activeProductionUnit: null,
    justificationDelayInSeconds: null,
    activeProductionUnitObjectives: [],
    activeProductionUnitTags: [],

    availability: null,
    performance: null,
    quality: null,
    oee: null,
    ooe: null,
    rejectQuantity: null,
    allProductsQuantity: null,
    currentProductQuantity: null,
    currentProductThroughput: null,
    currentProductSpeed5m: null,
    timeDistribution: null,
    wlvQuantity: null,
    giveaway: null,
    giveawaySku: null,
    productGiveaway: {
      value: null,
      valuePercent: null,
      target: null,
      targetPercent: null,
      unit: null,
    },
    averageProductGiveaway: {
      value: null,
      target: null,
      unit: null,
    },
    graphSelection: "oee",

    workShiftCoverage: [],
    productionRunCoverage: [],

    // is[placeholder]Loading controls whether a call is currently ongoing. This is so that if for whatever reason
    // a call hangs for a while, we don't start another one before it finishes.

    // is[placeholder]Loaded controls whether there is data to display. View will look at this to either show
    // a skeleton loader or the data. Should only be false on the initial page load and remain true afterwards
    // to avoid flashing cards.
    isMetricsLoading: false,
    isMetricsLoaded: false,
    isCurrentProductionRunLoading: false,
    isCurrentProductionRunLoaded: false,
    isTimelineLoading: false,
    isTimelineLoaded: false,
    isGraphLoading: false,
    isGraphLoaded: false,
    isGiveawayGraphLoading: false,
    isGiveawayGraphLoaded: false,
    productionUnitProducts: [],
  },

  getters: {
    // loaders
    isTilesLoaded(state) {
      return state.isMetricsLoaded || state.isCurrentProductionRunLoaded;
    },
    isPanelLoaded(state) {
      if (state.giveawayViewToggle) {
        return state.isGiveawayGraphLoaded;
      } else {
        return state.isTimelineLoaded;
      }
    },
    workShiftCoverage(state) {
      return state.workShiftCoverage;
    },
    productionRunCoverage(state) {
      return state.productionRunCoverage;
    },
    samplingSessionInProgress(state) {
      return state.samplingSessionInProgress;
    },
    giveawayViewToggle(state) {
      return state.giveawayViewToggle;
    },
    activeProductionUnitId(state) {
      return state.activeProductionUnitId;
    },
    tileSelection(state) {
      return state.tileSelection;
    },
    selectableTiles(state, getters, rootState, rootGetters) {
      return Tiles.getSelectableTilesByRole(rootGetters["user/loggedInUserRole"]);
    },
    productionUnitName(state, getters, rootState, rootGetters) {
      const productionUnits = rootGetters["navigation/activeFactoryProductionUnits"];
      const productionUnit = productionUnits.find((item) => item.id === state.activeProductionUnitId);
      return productionUnit?.name;
    },
    productionUnitConvertedUnit(state, getters, rootState, rootGetters) {
      const productionUnits = rootGetters["navigation/activeFactoryProductionUnits"];
      const productionUnit = productionUnits.find((item) => item.id === state.activeProductionUnitId);
      return productionUnit?.convertedUnitName;
    },
    productionUnitProducts(state) {
      return state.productionUnitProducts;
    },
    currentTimelineBlock(state) {
      if (state.timeline && state.timeline.blocks.length > 0) {
        return state.timeline.blocks[state.timeline.blocks.length - 1];
      }
    },
    currentTimelineBlockState(state, getters) {
      if (getters.currentTimelineBlock) {
        // if mixed-reasons downtime, use last
        if (getters.currentTimelineBlock.sub_blocks && getters.currentTimelineBlock.sub_blocks.length > 0) {
          return getters.currentTimelineBlock.sub_blocks[getters.currentTimelineBlock.sub_blocks.length - 1].state;
        } else return getters.currentTimelineBlock.state;
      }
    },
    currentProductionUnitDataSourceAlerts(state, getters, rootState, rootGetters) {
      const factoryAlerts = rootGetters["navigation/activeFactoryDataSourceAlerts"];
      return factoryAlerts.filter((a) => a.production_unit_ids.includes(state.activeProductionUnitId));
    },
    currentProductionUnitState(state, getters, rootState, rootGetters) {
      const factoryAlerts = rootGetters["navigation/activeFactoryDataSourceAlerts"];
      const alertsForActivePU = factoryAlerts.filter((a) =>
        a.production_unit_ids.includes(state.activeProductionUnitId),
      );

      if (alertsForActivePU && alertsForActivePU.length > 0) {
        return "connection_issues";
      } else {
        switch (getters.currentTimelineBlockState) {
          case "unknown":
          case "out_of_production":
          case "down_unjustified":
          case "down_unplanned":
          case "down_planned":
          case "up":
            return getters.currentTimelineBlockState;
          default:
            return "unknown";
        }
      }
    },
    currentProduct(state) {
      return state.currentProduct;
    },
    currentProductionRun(state) {
      return state.currentProductionRun;
    },
    currentWorkOrder(state) {
      return state.currentWorkOrder;
    },
    currentLot(state) {
      return state.currentLot;
    },
    cycleStats(state) {
      return state.cycleStats;
    },
    currentProductSku(state) {
      return state.currentProduct ? state.currentProduct.sku : null;
    },
    currentProductPUAssociations(state) {
      return state.currentProductPUAssociations;
    },
    currentProductProductionObjective(state) {
      if (state.currentProductPUAssociations.length === 0) {
        return null;
      }
      let puAssociation = null;
      // Get all associations for the `activeProductionUnitId`
      const associationsForActivePU = state.currentProductPUAssociations.filter(
        (a) => a.production_unit_id === state.activeProductionUnitId,
      );
      // Get the non-deleted association, if any
      const nonDeletedAssociation = associationsForActivePU.find((a) => !a.deletion_time);
      if (nonDeletedAssociation) {
        puAssociation = nonDeletedAssociation;
      } else {
        // All associations have been (soft) deleted, use the last created one
        associationsForActivePU.sort(Helpers.sortByCreationTimeDescendingFunction);
        puAssociation = associationsForActivePU[0];
      }
      if (puAssociation && puAssociation.objectives && puAssociation.objectives.length > 0) {
        // Get production objectives
        let objectives = puAssociation.objectives.filter(
          (o) => o.objective_type === ProductObjectives.rateObjectiveName,
        );
        if (objectives.length === 0) {
          return null;
        }
        // Get the non-deleted production objective, if any
        const nonDeletedObjective = objectives.find((o) => !o.deletion_time);
        if (nonDeletedObjective) {
          return nonDeletedObjective;
        } else {
          // All production objectives have been (soft) deleted, use the last created one
          objectives.sort(Helpers.sortByCreationTimeDescendingFunction);
          return objectives[0];
        }
      }
      return null;
    },
    timeline(state) {
      return state.timeline;
    },
    oeeGraph(state) {
      return state.oeeGraph;
    },
    quantityGraph(state) {
      return state.quantityGraph;
    },
    giveawayGraph(state) {
      return state.giveawayGraph;
    },
    currentDowntime(state, getters, rootState, rootGetters) {
      return rootGetters["navigation/isLiveData"] ? getters.currentTimelineBlock?.downtime : null;
    },
    currentDowntimeDuration(state) {
      return state.currentDowntimeDuration;
    },
    unjustifiedDowntimes(state, getters) {
      return state.timeline.blocks
        .filter((block) => block.state === "down_unjustified")
        .map((block) => block.downtime)
        .filter((downtime) => downtime.end_time - downtime.start_time >= getters.activePUJustificationDelayInMillis);
    },
    partiallyJustifiedDowntimes(state, getters) {
      return state.timeline.blocks
        .filter((block) => block.state === "down_mixed")
        .map((block) => block.downtime)
        .filter((downtime) => {
          const toJustifyBlocks = downtime.justifications
            .filter((j) => j.reason == null)
            .filter((j) => {
              const effectiveEndTime = j.end_time ? j.end_time : downtime.end_time;
              return effectiveEndTime - j.start_time >= getters.activePUJustificationDelayInMillis;
            });
          return toJustifyBlocks.length > 0;
        });
    },
    unjustifiedDowntimesBeforeSelected(state, getters) {
      const justifiableDowntimes = getters.unjustifiedDowntimes.filter(
        (d) => d.end_time - d.start_time >= getters.activePUJustificationDelayInMillis,
      );
      return justifiableDowntimes.filter((d) => d.start_time < getters.selectedDowntime.start_time);
    },
    downtimes(state) {
      return state.timeline.blocks.filter((block) => block.downtime).map((block) => block.downtime);
    },
    selectedDowntime(state, getters) {
      return getters.downtimes.find((downtime) => downtime.id === state.selectedDowntimeId);
    },
    selectedDowntimeId(state) {
      return state.selectedDowntimeId;
    },
    isBlockDown() {
      return (blockState) =>
        blockState === "down_unplanned" ||
        blockState === "down_unjustified" ||
        blockState === "down_planned" ||
        blockState === "down_mixed";
    },
    isJustifiableDowntime(state, getters) {
      return (block) => {
        return block.duration >= getters.activePUJustificationDelayInSeconds;
      };
    },
    justificationCommentMaxLength() {
      return 250;
    },
    productMarkers(state) {
      return state.timeline.markers.filter((m) => m.type === "ProductSelection");
    },
    shiftMarkers(state) {
      return state.timeline.markers.filter((m) => m.type === "WorkShiftStart");
    },
    availableDowntimeReasons(state) {
      return state.availableDowntimeReasons;
    },
    availableRejectReasons(state) {
      return state.availableRejectReasons;
    },
    activeProductionUnit(state) {
      return state.activeProductionUnit;
    },
    activePUJustificationDelayInSeconds(state) {
      let delay = state.justificationDelayInSeconds;
      return delay && delay > 0 ? delay : 0;
    },
    activePUJustificationDelayInMillis(state) {
      let delay = state.justificationDelayInSeconds;
      return delay && delay > 0 ? 1000 * delay : 0;
    },
    availability(state) {
      return state.availability;
    },
    availabilityTarget(state) {
      const target = state.activeProductionUnitObjectives.find(
        (objective) => objective.objective_type === "availability",
      );
      if (target && target.value) {
        return Helpers.round(target.value, 1);
      } else {
        return null;
      }
    },
    performance(state) {
      return state.performance;
    },
    performanceTarget(state) {
      const target = state.activeProductionUnitObjectives.find(
        (objective) => objective.objective_type === "performance",
      );
      if (target && target.value) {
        return Helpers.round(target.value, 1);
      } else {
        return null;
      }
    },
    quality(state) {
      return state.quality;
    },
    rejectQuantity(state) {
      return state.rejectQuantity;
    },
    allProductsQuantity(state) {
      return state.allProductsQuantity;
    },
    currentProductQuantity(state) {
      return state.currentProductQuantity;
    },
    currentProductThroughput(state) {
      return state.currentProductThroughput;
    },
    currentProductSpeed5m(state) {
      return state.currentProductSpeed5m;
    },
    timeDistribution(state) {
      return state.timeDistribution;
    },
    productGiveaway(state) {
      return state.productGiveaway;
    },
    averageProductGiveaway(state) {
      return state.averageProductGiveaway;
    },
    oee(state) {
      return state.oee; // Overall EQUIPMENT Effectiveness
    },
    oeeTarget(state) {
      const target = state.activeProductionUnitObjectives.find((objective) => objective.objective_type === "oee");
      if (target && target.value) {
        return Helpers.round(target.value, 1);
      } else {
        return null;
      }
    },
    ooe(state) {
      return state.ooe; // Overall OPERATION Effectiveness. Be careful; this is not the oee!
    },
    qualityTarget(state) {
      const target = state.activeProductionUnitObjectives.find((objective) => objective.objective_type === "quality");
      if (target && target.value) {
        return Helpers.round(target.value, 1);
      } else {
        return null;
      }
    },
    oldestDataSourceAlert(state, getters, rootState, rootGetters) {
      const alerts = rootGetters["navigation/activeFactoryDataSourceAlerts"];
      if (alerts && alerts.length > 0) {
        let activePUAlerts = alerts.filter((a) => a.production_unit_ids.includes(state.activeProductionUnitId));
        activePUAlerts = activePUAlerts.sort(SortUtils.sortAlertsByDurationDescending);
        if (activePUAlerts.length > 0) {
          return activePUAlerts[0];
        } else {
          return null;
        }
      }
      return null;
    },
    isActiveTile: (state) => (tile) => {
      return state.tileSelection.find((id) => id === tile.name) !== undefined;
    },
    graphSelection(state) {
      return state.graphSelection;
    },
    activeProductionUnitTags(state) {
      return state.activeProductionUnitTags;
    },
  },

  actions: {
    setActiveProductionUnitId({ commit, dispatch, rootGetters }, activeProductionUnitId) {
      commit("setActiveProductionUnitId", activeProductionUnitId);
      commit("setGiveawayViewToggle", false);

      let dashboards = rootGetters["user/preferences"].dashboards;
      const tiles = dashboards.production_units.find((pu) => pu.id === activeProductionUnitId).tiles;
      const graph = dashboards.production_units.find((pu) => pu.id === activeProductionUnitId).graph;
      const graphSelection = graph !== null ? graph : "oee";
      commit("setCurrentTileSelection", UserService.buildTileSelection(tiles));
      commit("setCurrentGraphSelection", graphSelection);

      dispatch("user/updateSelectedProductionUnitId", activeProductionUnitId, { root: true });
    },

    setSamplingSessionInProgress({ commit }, samplingSession) {
      commit("setSamplingSessionInProgress", samplingSession);
    },

    fetchDashboard({ dispatch, getters, state, rootGetters, commit }) {
      dispatch("navigation/setCurrentDateTime", null, { root: true });
      const isUserLoggedIn = rootGetters["user/isLoggedIn"];
      if (!isUserLoggedIn || !state.activeProductionUnitId) return;

      const timeAggregation = getQuantityGraphTimeAggregation(rootGetters["navigation/durationMillis"]);
      const selectedProductionRun = rootGetters["navigation/selectedProductionRun"];
      const optionalProductionRunOptions = selectedProductionRun
        ? {
            sku: selectedProductionRun.sku,
            workOrderId: selectedProductionRun.work_order_id,
            lotId: selectedProductionRun.lot_id,
          }
        : null;

      if (!rootGetters["navigation/startISO"] || !rootGetters["navigation/endISO"]) return;

      dispatch("fetchProductionUnit").then(() => {
        dispatch("fetchDashboardMetrics", { optionalProductionRunOptions });
        // stop here if we're only fetching the metrics
        if (isDurationGreaterThanNDays(rootGetters["navigation/durationMillis"], 3)) {
          commit("setLoader", ["isTimelineLoading", false]);
          commit("setLoader", ["isGraphLoading", false]);
          commit("setLoader", ["isTimelineLoaded", true]);
          commit("setLoader", ["isGraphLoaded", true]);
          return;
        }

        if (getters.giveawayViewToggle) {
          // only fetch giveaway graph elements
          dispatch("fetchGiveawayGraphElements");
        } else {
          // fetch timeline and graph elements
          dispatch("fetchTimelineElements");
          dispatch("fetchGraphElements", { timeAggregation });
        }
      });
    },

    fetchProductionUnit({ commit, state }) {
      ProductionUnitService.getProductionUnit(state.activeProductionUnitId)
        .then((httpResponse) => {
          if (httpResponse.status === 200) {
            let productionUnit = httpResponse.data;
            commit("setActiveProductionUnit", productionUnit);
            commit("setActiveProductionUnitObjectives", productionUnit.objectives);
          }
        })
        .catch((error) =>
          commit(
            "operation/showOperationError",
            ErrorHandling.buildErrorsMessages(error.response, (code) =>
              i18n.t("common.errors.default", { code: code }),
            ),
            { root: true },
          ),
        );
    },

    fetchTimelineElements({ commit, dispatch, state, rootGetters }) {
      if (state.isTimelineLoading) return;
      commit("setLoader", ["isTimelineLoading", true]);
      return TimelineService.getTimelineElements(
        state.activeProductionUnitId,
        rootGetters["navigation/startISO"],
        rootGetters["navigation/endISO"],
      )
        .then((response) => {
          let startingSku = response.data.starting_sku;
          commit("setStartingSku", startingSku);
          let currentProduct = response.data.current_product;
          commit("setCurrentProduct", currentProduct);
          if (currentProduct && currentProduct.id) {
            dispatch("fetchProductObjectives", currentProduct.id);
          }
          commit("setCurrentWorkOrder", response.data.current_work_order);
          commit("setCurrentLot", response.data.current_lot);
          commit("setCycleStats", response.data.cycle_time_stats);
          commit("setTimeline", response.data);
          commit("setLoader", ["isTimelineLoading", false]);
          commit("setLoader", ["isTimelineLoaded", true]);
        })
        .catch((error) => {
          commit(
            "operation/showOperationError",
            ErrorHandling.buildErrorsMessages(error.response, (code) =>
              i18n.t("common.errors.default", { code: code }),
            ),
            { root: true },
          );
          commit("setLoader", ["isTimelineLoading", false]);
          commit("setLoader", ["isTimelineLoaded", true]);
        });
    },

    fetchProductObjectives({ commit }, productId) {
      const includeDeleted = true;
      return ProductService.getProduct(productId, includeDeleted)
        .then((httpResponse) => {
          if (httpResponse.status === 200) {
            let product = httpResponse.data;
            if (product && product.associated_production_units) {
              commit("setCurrentProductPUAssociations", product.associated_production_units);
            }
          }
        })
        .catch((error) =>
          commit(
            "operation/showOperationError",
            ErrorHandling.buildErrorsMessages(error.response, (code) =>
              i18n.t("common.errors.default", { code: code }),
            ),
            { root: true },
          ),
        );
    },

    fetchGraphElements({ commit, getters, state, rootGetters }, { timeAggregation, optionalProductionRunOptions }) {
      if (getters.giveawayViewToggle) return;
      if (state.isGraphLoading) return;

      commit("setLoader", ["isGraphLoading", true]);
      GraphService.getGraphElements(
        state.activeProductionUnitId,
        rootGetters["navigation/startISO"],
        rootGetters["navigation/endISO"],
        timeAggregation,
        optionalProductionRunOptions,
      )
        .then((response) => {
          // If the following condition is FALSE, the request was called for a production line,
          // and the user switched lines before the call finished, in effect "cancelling" the previous
          // call and launching a new one.
          // When the initial call returns, ignore it since it will be setting wrong data, and setting
          // the refreshing running variable to false when the second one might not be finished.
          const requestForProductionUnitId = response.config.url.split("/")[2];
          if (state.activeProductionUnitId === requestForProductionUnitId) {
            commit("setGraph", response.data);
          }
          commit("setLoader", ["isGraphLoading", false]);
          commit("setLoader", ["isGraphLoaded", true]);
        })
        .catch((error) => {
          commit("setLoader", ["isGraphLoading", false]);
          commit("setLoader", ["isGraphLoaded", true]);
          commit(
            "operation/showOperationError",
            ErrorHandling.buildErrorsMessages(error.response, (code) =>
              i18n.t("common.errors.default", { code: code }),
            ),
            { root: true },
          );
        });
    },

    fetchGiveawayGraphElements({ commit, getters, state, rootGetters }) {
      if (state.isGiveawayGraphLoading) return;
      // make sure the chart type provided id a valid one
      // if (["sampling", "check_weigher"].find(chartType) === undefined) return;
      if (
        !rootGetters["packages/activePuHasRequiredFeature"](Tiles.productGiveaway.requiredFeature) ||
        !getters.giveawayViewToggle
      ) {
        // The active Production Unit is not configured with the feature `Giveaway`, leave it to `null`.
        commit("setGiveawayGraph", null);
        return;
      }

      commit("setLoader", ["isGiveawayGraphLoading", true]);
      let chartType = "check_weigher";
      if (state.activeProductionUnit.giveaway_sampling_configuration !== null) chartType = "sampling"; // switch to sampling if configuration exists for PU

      GraphService.getGiveawayElements(
        state.activeProductionUnitId,
        rootGetters["navigation/startISO"],
        rootGetters["navigation/endISO"],
        chartType,
      )
        .then((response) => {
          commit("setGiveawayGraph", response.data);
          commit("setLoader", ["isGiveawayGraphLoading", false]);
          commit("setLoader", ["isGiveawayGraphLoaded", true]);
        })
        .catch((error) => {
          commit(
            "operation/showOperationError",
            ErrorHandling.buildErrorsMessages(error.response, (code) =>
              i18n.t("common.errors.default", { code: code }),
            ),
            { root: true },
          );
          commit("setLoader", ["isGiveawayGraphLoading", false]);
          commit("setLoader", ["isGiveawayGraphLoaded", true]);
        });
    },

    fetchAvailableDowntimeReasons({ commit, state }, { downtimeId }) {
      DowntimeJustificationService.getAvailableDowntimeReasons(state.activeProductionUnitId, downtimeId)
        .then((response) => {
          if (response.status === 200) {
            commit("setAvailableDowntimeReasons", response.data);
          }
        })
        .catch((error) =>
          commit(
            "operation/showOperationError",
            ErrorHandling.buildErrorsMessages(error.response, (code) =>
              i18n.t("common.errors.default", { code: code }),
            ),
            { root: true },
          ),
        );
    },

    fetchAvailableRejectReasons({ commit, state }) {
      return RejectReasonService.getAvailableRejectReasons(state.activeProductionUnitId)
        .then((response) => {
          if (response.status === 200) {
            commit("setAvailableRejectReasons", response.data);
          }
        })
        .catch((error) =>
          commit(
            "operation/showOperationError",
            ErrorHandling.buildErrorsMessages(error.response, (code) =>
              i18n.t("common.errors.default", { code: code }),
            ),
            { root: true },
          ),
        );
    },

    justifyDowntime({ commit }, { downtimeId, justifications }) {
      return DowntimeJustificationService.setDowntimeJustifications(downtimeId, justifications)
        .then(() => {
          commit("operation/showOperationSuccess", i18n.t("justification.successfullyUpdated"), { root: true });
        })
        .catch((error) => {
          commit(
            "operation/showOperationError",
            ErrorHandling.buildErrorsMessages(error.response, (code) => {
              switch (code) {
                case "DSH_DJUST_PUT_20001":
                  return i18n.t("justification.errors.downtimeNotFound");
                case "DSH_DJUST_PUT_20002":
                  return i18n.t("justification.errors.durationSumExceeded");
                case "DSH_DJUST_PUT_20003":
                  return i18n.t("justification.errors.unknownReason");
                default:
                  return i18n.t("common.errors.default", { code: error });
              }
            }),
            { root: true },
          );
        });
    },

    submitRejects({ commit, state, rootGetters }, { rejectPayload }) {
      if (!rootGetters["packages/activePuHasRequiredFeature"](PackageFeatures.reject)) {
        let errorMessage = i18n.t("dashboard.manualRejectsEntry.errors.puMissingPackage", {
          puName: state.activeProductionUnit.name,
        });
        commit("operation/showOperationError", errorMessage, { root: true });
        return;
      }
      return RejectService.submitReject(state.activeProductionUnitId, rejectPayload).then(() => {
        commit("operation/showOperationSuccess", i18n.t("dashboard.manualRejectsEntry.successfullyApplied"), {
          root: true,
        });
      });
    },

    updateProductionRun({ commit, state }, productionRunRequest) {
      const productionRunId = productionRunRequest.eventId;
      let request = productionRunRequest;
      delete request.eventId;
      return ProductionRunService.updateProductionRun(state.activeProductionUnitId, productionRunId, request)
        .then(() => {
          commit("operation/showOperationSuccess", i18n.t("dashboard.productionRun.successfullyUpdated"), {
            root: true,
          });
        })
        .catch((error) => {
          commit(
            "operation/showOperationError",
            ErrorHandling.buildErrorsMessages(error.response, (code) => {
              if (code === "DSH_PRODRUN_PUT_10009") {
                return i18n.t("dashboard.productionRun.errors.conflictStartTime");
              }
              if (code === "DSH_PRODRUN_PUT_10012") {
                return i18n.t("dashboard.productionRun.errors.noProductionRunFound");
              }
              return i18n.t("common.errors.default", { code: code });
            }),
            { root: true },
          );
        });
    },

    createProductionRun({ commit, state, rootGetters }, productionRunRequest) {
      return ProductionRunService.createProductionRun(state.activeProductionUnitId, productionRunRequest)
        .then((httpResponse) => {
          const startTimeMillisUtc = httpResponse.data.effective_start_time;
          const startTime = TimeUtils.getTimeFromMillis(
            startTimeMillisUtc,
            rootGetters["navigation/activeFactory"]?.timeZone,
          );
          const startDate = TimeUtils.getDateFromMillis(
            startTimeMillisUtc,
            rootGetters["navigation/activeFactory"]?.timeZone,
          );
          commit(
            "operation/showOperationSuccess",
            i18n.t("dashboard.productionRun.successfullyCreated", { startDate, startTime }),
            {
              root: true,
            },
          );
        })
        .catch((error) => {
          commit(
            "operation/showOperationError",
            ErrorHandling.buildErrorsMessages(error.response, (code) => {
              if (code === "DSH_PRODRUN_POST_10009") {
                return i18n.t("dashboard.productionRun.errors.conflictStartTime");
              }

              return i18n.t("common.errors.default", { code: code });
            }),
            { root: true },
          );
        });
    },

    deleteProductionRun({ commit, state }, productionDeletionRequest) {
      return ProductionRunService.deleteProductionRun(
        state.activeProductionUnitId,
        productionDeletionRequest.productionUnitEventId,
      )
        .then(() => {
          commit(
            "operation/showOperationSuccess",
            i18n.t("dashboard.productionRun.deleteProductionRun.successfullyDeleted"),
            {
              root: true,
            },
          );
        })
        .catch((error) => {
          commit(
            "operation/showOperationError",
            ErrorHandling.buildErrorsMessages(error.response, (code) => {
              if (code === "DSH_PRODRUN_DELETE_10013") {
                const sku = productionDeletionRequest.sku
                  ? productionDeletionRequest.sku
                  : i18n.t("dashboard.panelHeader.unspecifiedProduct");
                return i18n.t("dashboard.productionRun.errors.productNotFound", { sku: sku });
              }
              if (code === "DSH_PRODRUN_DELETE_10012") {
                return i18n.t("dashboard.productionRun.errors.noProductionRunFound");
              }
              return i18n.t("common.errors.default", { code: code });
            }),
            { root: true },
          );
        });
    },

    selectDowntime({ commit }, id) {
      commit("setSelectedDowntime", id);
    },

    selectPreviousDowntime({ commit, getters, state }) {
      const justifiableDowntimes = getters.downtimes.filter(
        (downtime) => downtime.end_time - downtime.start_time >= getters.activePUJustificationDelayInMillis,
      );
      let index = justifiableDowntimes.findIndex((downtime) => downtime.id === state.selectedDowntimeId);
      if (index > 0) {
        commit("setSelectedDowntime", justifiableDowntimes[index - 1].id);
      }
    },

    selectNextDowntime({ commit, getters, state }) {
      const justifiableDowntimes = getters.downtimes.filter(
        (downtime) => downtime.end_time - downtime.start_time >= getters.activePUJustificationDelayInMillis,
      );
      let index = justifiableDowntimes.findIndex((downtime) => downtime.id === state.selectedDowntimeId);
      if (index < justifiableDowntimes.length - 1) {
        commit("setSelectedDowntime", justifiableDowntimes[index + 1].id);
      }
    },

    selectLastDowntime({ commit, getters }) {
      const justifiableDowntimes = getters.downtimes.filter(
        (downtime) => downtime.end_time - downtime.start_time >= getters.activePUJustificationDelayInMillis,
      );
      const lastDowntimeId = justifiableDowntimes[justifiableDowntimes.length - 1]?.id;
      if (lastDowntimeId) {
        commit("setSelectedDowntime", lastDowntimeId);
      }
    },

    selectPreviousDowntimeUnjustified({ commit, getters }) {
      let olderUnjustifiedDowntimes = getters.unjustifiedDowntimesBeforeSelected;
      if (olderUnjustifiedDowntimes.length > 0) {
        commit("setSelectedDowntime", olderUnjustifiedDowntimes[olderUnjustifiedDowntimes.length - 1].id);
      }
    },

    selectLastDowntimeUnjustified({ commit, getters }) {
      const lastDowntimeUnjustifiedId = getters.unjustifiedDowntimes[getters.unjustifiedDowntimes.length - 1]?.id;
      if (lastDowntimeUnjustifiedId) {
        commit("setSelectedDowntime", lastDowntimeUnjustifiedId);
      }
    },

    unselectDowntime({ commit }) {
      commit("setSelectedDowntime", null);
    },

    setCurrentDowntimeDuration({ commit, getters, state, rootGetters }) {
      let downtime = getters.currentDowntime;
      if (downtime) {
        const isLiveData = rootGetters["navigation/isLiveData"];
        if (isLiveData) {
          let now = Date.now();
          commit("setCurrentDowntimeDuration", ((now - downtime.start_time) / 1000) * 1000);
        } else {
          commit("setCurrentDowntimeDuration", 0);
        }
      } else if (state.currentDowntimeDuration > 0) {
        commit("setCurrentDowntimeDuration", 0);
      }
    },

    fetchDashboardMetrics(
      { commit, dispatch, state, getters, rootGetters },
      { optionalProductionRunOptions },
    ) {
      if (state.isMetricsLoading) return;
      commit("setLoader", ["isMetricsLoading", true]);
      TileService.getProductionUnitMetrics(
        state.activeProductionUnitId,
        rootGetters["navigation/timeFrame"],
        rootGetters["navigation/startISO"],
        rootGetters["navigation/endISO"],
        rootGetters["navigation/date"], // Business Day as selected by the user
        optionalProductionRunOptions,
      )
        .then((response) => {
          commit("setLoader", ["isMetricsLoading", false]);

          let currentSku = null;
          if (getters.giveawayViewToggle) {
            currentSku = state.giveawaySku;
          } else if (optionalProductionRunOptions) {
            currentSku = optionalProductionRunOptions.sku;
          } else {
            currentSku = getters.currentProductSku ? getters.currentProductSku : "";
          }

          if (response.status === 200 && response.data[0]) {
            commit("setMetrics", { metrics: response.data[0].metrics, currentSku: currentSku });
            commit("setAverageProductGiveaway", currentSku);
            commit("setProductGiveaway", currentSku);
            commit("setLoader", ["isMetricsLoaded", true]);
          }
        })
        .catch((error) => {
          commit("setLoader", ["isMetricsLoading", false]);
          commit("setLoader", ["isMetricsLoaded", true]);
          commit(
            "operation/showOperationError",
            ErrorHandling.buildErrorsMessages(error.response, (code) =>
              i18n.t("common.errors.default", { code: code }),
            ),
            { root: true },
          );
        });
      dispatch("fetchCurrentProductSpeed5m");
      dispatch("fetchCurrentProductionRun");
    },

    fetchCurrentProductSpeed5m({ commit, state, getters, rootGetters }) {
      if (
        !getters.isActiveTile(Tiles.currentProductSpeed5m) &&
        !getters.isActiveTile(Tiles.currentProductSpeed5mPerMinute)
      )
        return;

      if (rootGetters["navigation/isLiveData"]) {
        const currentTimelineCursorTime = rootGetters["navigation/currentDateTime"];
        const coverageEndDateTime = currentTimelineCursorTime.set({ seconds: 0, milliseconds: 0 }).plus({ minutes: 1 });
        const coverageStartDateTime = coverageEndDateTime.minus({ minutes: 5 });
        const coverageStart = coverageStartDateTime.toFormat("yyyy-LL-dd'T'HH:mm:ssZZ");
        const coverageEnd = coverageEndDateTime.toFormat("yyyy-LL-dd'T'HH:mm:ssZZ");

        TileService.getProductSpeed(state.activeProductionUnitId, coverageStart, coverageEnd, getters.currentProductSku)
          .then((response) => {
            commit("setCurrentProductSpeed5m", response.data.product_speed);
          })
          .catch((error) =>
            commit(
              "operation/showOperationError",
              ErrorHandling.buildErrorsMessages(error.response, (code) =>
                i18n.t("common.errors.default", { code: code }),
              ),
              { root: true },
            ),
          );
      } else {
        commit("setCurrentProductSpeed5m", null);
      }
    },

    setGiveawayForSku({ commit }, sku) {
      commit("setProductGiveaway", sku);
    },

    setAverageProductGiveawayForSku({ commit }, sku) {
      commit("setAverageProductGiveaway", sku);
    },

    fetchCurrentProductionRun({ commit, state, getters, dispatch }) {
      if (state.isCurrentProductionRunLoading) return;

      dispatch("navigation/setCurrentDateTime", null, { root: true });
      const canUseCurrentProductionRun = getters.isActiveTile(Tiles.production);
      if (!canUseCurrentProductionRun) return;
      TileService.getCurrentProductionRun(state.activeProductionUnitId)
        .then((response) => {
          if (response.status === 200) {
            commit("setCurrentProductionRun", response.data);
          }
          if (response.status === 204) {
            commit("setCurrentProductionRun", null);
          }
          commit("setLoader", ["isCurrentProductionRunLoading", false]);
          commit("setLoader", ["isCurrentProductionRunLoaded", true]);
        })
        .catch((error) => {
          commit(
            "operation/showOperationError",
            ErrorHandling.buildErrorsMessages(error.response, (code) =>
              i18n.t("common.errors.default", { code: code }),
            ),
            { root: true },
          );
          commit("setLoader", ["isCurrentProductionRunLoading", false]);
          commit("setLoader", ["isCurrentProductionRunLoaded", true]);
        });
    },

    setTileByIndex({ commit, dispatch }, { tile, index }) {
      commit("setTileByIndex", { tile, index });
      dispatch("user/updateActiveProductionUnitTileSelection", {}, { root: true });
    },

    setJustificationDelayInSeconds({ commit }, delayInSeconds) {
      commit("setJustificationDelayInSeconds", delayInSeconds);
    },

    setGiveawayViewToggle({ commit }, value) {
      commit("setGiveawayViewToggle", value);
    },

    fetchProductionUnitCoverage({ commit, state, rootGetters, dispatch }, coverage) {
      dispatch("navigation/setCurrentDateTime", null, { root: true });
      let isUserLoggedIn = rootGetters["user/isLoggedIn"];
      if (isUserLoggedIn && state.activeProductionUnitId && state.activeProductionUnit) {
        TimelineService.getProductionUnitCoverage(
          state.activeProductionUnit.factory_id,
          state.activeProductionUnitId,
          coverage.startTime,
          coverage.endTime,
        )
          .then((response) => {
            const productionUnitCoverage = response.data["production_units_coverage"]?.find(
              (puc) => puc.production_unit_id === state.activeProductionUnitId,
            );
            if (!productionUnitCoverage) {
              // should never happen, if production unit does not exist API returns an error
              // in this case should we fail gracefully or throw an error and show
              // operation error?
              commit("setProductionRunsCoverage", []);
              commit("setWorkShiftsCoverage", []);
            }
            const sortedProductionRunsCoverage = productionUnitCoverage["production_runs_coverage"].sort(
              SortUtils.sortCoverageByStartDateDesc,
            );
            const sortedWorkShiftsCoverage = productionUnitCoverage["work_shifts_coverage"]
              .filter((a) => a.work_shift_name !== null && a.work_shift_id !== null)
              .sort(SortUtils.sortCoverageByStartDateDesc);
            commit("setProductionRunsCoverage", sortedProductionRunsCoverage);
            commit("setWorkShiftsCoverage", sortedWorkShiftsCoverage);
          })
          .catch((error) => {
            commit(
              "operation/showOperationError",
              ErrorHandling.buildErrorsMessages(error.response, (code) => {
                switch (code) {
                  case "DSH_COV_GET_10007":
                  case "DSH_COV_GET_10008":
                    return null; // No error messages for this backend error code. It's a race condition.
                  default:
                    return i18n.t("common.errors.default", { code: error });
                }
              }),
              { root: true },
            );
          });
      }
    },

    fetchProductionUnitProducts({ commit, state, rootGetters }) {
      if (!rootGetters["navigation/activeFactory"]) return;
      const activeProductionUnitId = state.activeProductionUnitId;
      if (activeProductionUnitId !== "" && activeProductionUnitId !== null && activeProductionUnitId !== undefined) {
        ProductService.getProductionUnitProducts(activeProductionUnitId)
          .then((response) => {
            if (response.status === 200) {
              commit("setProductionUnitProducts", response.data);
            }
          })
          .catch((error) =>
            commit(
              "operation/showOperationError",
              ErrorHandling.buildErrorsMessages(error.response, (code) =>
                i18n.t("common.errors.default", { code: code }),
              ),
              { root: true },
            ),
          );
      }
    },

    fetchProductionUnitTags({ commit, state }) {
      const activeProductionUnitId = state.activeProductionUnitId;
      if (!activeProductionUnitId || activeProductionUnitId === "") return;

      ProductionUnitService.getProductionUnitTags(activeProductionUnitId)
        .then((httpResponse) => {
          commit("setProductionUnitTags", httpResponse.data);
        })
        .catch((error) => {
          console.error(error);
          commit("setProductionUnitTags", []);
        });
    },

    setActiveProductionUnitTag({ commit, state }, tagId) {
      const activeProductionUnitId = state.activeProductionUnitId;
      if (!activeProductionUnitId || activeProductionUnitId === "") return;

      ProductionUnitService.updateProductionUnitTag(activeProductionUnitId, tagId)
        .then((httpResponse) => {
          const activeTags = state.activeProductionUnitTags;
          activeTags.unshift(httpResponse.data); // set at the beginning of the array to be active
          commit("setProductionUnitTags", activeTags);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    removeActiveProductionUnitTag({ commit, state }, tagId) {
      const activeProductionUnitId = state.activeProductionUnitId;
      if (!activeProductionUnitId || activeProductionUnitId === "") return;

      ProductionUnitService.deleteProductionUnitTag(activeProductionUnitId, tagId)
        .then(() => {
          const activeTags = state.activeProductionUnitTags;
          const deletedTagIndex = activeTags.findIndex((t) => t.id === tagId);
          if (deletedTagIndex >= 0) {
            activeTags.splice(deletedTagIndex, 1); // remove the deleted tag from the list of active tags
            commit("setProductionUnitTags", activeTags);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    resetDashboardLoaders({ commit }) {
      commit("setLoader", ["isMetricsLoaded", false]);
      commit("setLoader", ["isCurrentProductionRunLoaded", false]);
      commit("setLoader", ["isTimelineLoaded", false]);
      commit("setLoader", ["isGraphLoaded", false]);
      commit("setLoader", ["isGiveawayGraphLoaded", false]);
    },
  },

  mutations: {
    setLoader(state, [loader, value]) {
      state[loader] = value;
    },
    setGiveawayViewToggle(state, isGiveawayView) {
      state.giveawayViewToggle = isGiveawayView;
    },
    setActiveProductionUnitId(state, activeProductionUnitId) {
      state.activeProductionUnitId = activeProductionUnitId;
    },
    setSamplingSessionInProgress(state, samplingSession) {
      state.samplingSessionInProgress = samplingSession;
    },
    setCurrentTileSelection(state, newCurrentTileSelection) {
      state.tileSelection = newCurrentTileSelection;
    },
    setTileByIndex(state, { tile, index }) {
      Vue.set(state.tileSelection, index, tile);
    },
    setCurrentProduct(state, product) {
      state.currentProduct = product;
    },
    setCurrentProductionRun(state, production) {
      state.currentProductionRun = production;
    },
    setCurrentWorkOrder(state, workOrder) {
      state.currentWorkOrder = workOrder;
    },
    setCurrentLot(state, lot) {
      state.currentLot = lot;
    },
    setStartingSku(state, sku) {
      state.startingSku = sku;
    },
    setCurrentProductPUAssociations(state, associations) {
      state.currentProductPUAssociations = associations;
    },
    setCycleStats(state, cycleStats) {
      state.cycleStats = cycleStats;
    },
    setTimeline(state, payload) {
      state.timeline.offsetFromMidnight = payload.start.offset_from_midnight;
      state.timeline.coverageProgress = payload.coverage.elapsed_time;
      state.timeline.coverageDuration = payload.coverage.total_duration;
      state.timeline.blocks = payload.blocks
        .map((b) => ({
          start: b.start,
          duration: b.duration,
          state: b.state.toLowerCase(),
          sub_blocks: b.sub_blocks?.map((sb) => ({
            duration: sb.duration,
            state: sb.state.toLowerCase(),
          })),
          downtime: b.downtime,
        }))
        .filter((b) => b.duration > 0);
      state.timeline.markers = payload.markers;
    },
    setGraph(state, payload) {
      // Set OEE Graph
      state.oeeGraph.coverageDuration = payload.coverage.total_duration;
      state.oeeGraph.interval = payload.graph_oee.interval_in_seconds;

      state.oeeGraph.offset = payload.graph_oee.offset_from_start;
      state.oeeGraph.target = payload.graph_oee.target;
      state.oeeGraph.labels = payload.graph_oee.values.map(() => "");
      state.oeeGraph.curveData = payload.graph_oee.values;
      state.oeeGraph.oeeSources = payload.graph_oee.oee_sources.map((s) => ({
        timestamp: s.timestamp,
        availability: s.a,
        performance: s.p,
        quality: s.q,
        totalQuantity: s.qty,
        totalQuantityBySku: s.qty_by_sku,
      }));

      // Set Quantity Graph
      state.quantityGraph.labels = payload.graph_sku_quantity.skus_intervals.map((x) => {
        return { start_time: x.start_time, end_time: x.end_time };
      });

      state.quantityGraph.quantityData = payload.graph_sku_quantity.skus_intervals
        // keep data points with quantities
        .filter((x) => x.sku_quantities != null)
        // build quantities with start and end time
        .map((x) => {
          return x.sku_quantities.map((y) => {
            return {
              start_time: x.start_time,
              end_time: x.end_time,
              ...y,
            };
          });
        })
        .flat()
        // Prepare data for stacked bar chart by grouping quantities by sku
        .reduce((prev, cur) => {
          if (!prev[cur["sku"]]) {
            prev[cur["sku"]] = [];
          }
          prev[cur["sku"]].push(cur);
          return prev;
        }, {});
    },
    setGiveawayGraph(state, payload) {
      let products = payload.products.map(x => {
        let product = {...x};

        if (!x.product_description) {
          product.product_description = null;
        }

        if (!x.upper_tolerance) {
          product.upper_tolerance = null;
        }

        if (!x.lower_tolerance) {
          product.lower_tolerance = null;
        }

        if (!x.target_value) {
          product.target_value = null;
        }

        if (!x.target_unit) {
          product.target_unit = null;
        }

        return product;
      });
      payload.products = products;
      state.giveawayGraph = payload;
    },
    setSelectedDowntime(state, downtimeId) {
      state.selectedDowntimeId = downtimeId;
    },
    setAvailableDowntimeReasons(state, payload) {
      state.availableDowntimeReasons = payload;
    },
    setAvailableRejectReasons(state, payload) {
      state.availableRejectReasons = payload;
    },
    setCurrentDowntimeDuration(state, value) {
      state.currentDowntimeDuration = value;
    },
    setActiveProductionUnitObjectives(state, value) {
      state.activeProductionUnitObjectives = value;
    },
    setActiveProductionUnit(state, value) {
      state.activeProductionUnit = value;
      state.justificationDelayInSeconds = value.downtime_justification_delay_in_seconds;
    },
    setMetrics(state, metricsAndCurrentSku) {
      const metrics = metricsAndCurrentSku.metrics;
      const currentSku = metricsAndCurrentSku.currentSku;

      state.timeDistribution = {
        uptime: metrics.time_distribution.total_uptime,
        downtime_planned: metrics.time_distribution.total_planned_downtime,
        downtime_unplanned: metrics.time_distribution.total_unplanned_downtime,
        downtime_unjustified: metrics.time_distribution.total_unjustified_downtime,
        out_of_production_time: metrics.time_distribution.total_out_of_production_time,
        unknown_time: metrics.time_distribution.total_unknown_time,
        total_available_time: metrics.time_distribution.total_available_time,
        total_production_time: metrics.time_distribution.total_production_time,
        time_distribution_by_sku: metrics.time_distribution.time_distribution_by_sku,
      };

      state.allProductsQuantity = metrics.produced_quantity.total_count;
      state.currentProductQuantity = metrics.produced_quantity.quantity_by_sku[currentSku]?.count;
      state.currentProductThroughput = metrics.produced_quantity.quantity_by_sku[currentSku]?.count_throughput;

      state.availability = metrics.availability.value;
      state.performance = metrics.performance.value;
      state.quality = metrics.quality.value;
      state.oee = metrics.oee;
      state.ooe = metrics.ooe;

      state.rejectQuantity = metrics.reject_quantity.total_count;

      state.wlvQuantity = metrics.weight_length_volume_quantity;
      state.giveaway = metrics.giveaway;

      // const wlvQuantityForSKU = metrics.weight_length_volume_quantity.quantity_by_sku[currentSku];
      // const giveawayForCurrentSKU = metrics.giveaway.giveaway_by_sku[currentSku];

      // let qty = giveawayForCurrentSKU?.giveaway_quantity;
      // if (qty === null || qty === undefined) {
      //   qty = 0.0;
      // }
      // let configuredUnit = wlvQuantityForSKU?.count_and_quantity?.configured_unit
      //   ? wlvQuantityForSKU?.count_and_quantity?.configured_unit
      //   : "count";
      // let standardUnit = wlvQuantityForSKU?.count_and_quantity?.standard_unit
      //   ? wlvQuantityForSKU?.count_and_quantity?.standard_unit
      //   : "count";
      // let quantityInConfiguredUnit = qty;
      // if (configuredUnit && standardUnit && configuredUnit !== "count" && standardUnit !== "count") {
      //   quantityInConfiguredUnit = convert(qty)
      //     .from(standardUnit.toLowerCase()) // `L` and `mL` are not supported as is by this library. It must be `l` and `ml`!
      //     .to(configuredUnit);
      // }
      // state.productGiveaway.value = Helpers.round(quantityInConfiguredUnit, 1);
      // state.productGiveaway.valuePercent = Helpers.round(giveawayForCurrentSKU?.giveaway_percentage, 1);
      // state.productGiveaway.target = giveawayForCurrentSKU?.product_target;
      // state.productGiveaway.targetPercent = giveawayForCurrentSKU?.configured_objective_percentage;
      // state.productGiveaway.unit = configuredUnit;



      // let avgWLV = giveawayForCurrentSKU?.average_weight_length_volume;
      // console.log(avgWLV);
      // if (avgWLV === null || avgWLV === undefined) {
      //   avgWLV = 0.0;
      // }
      // let avgWLVInConfiguredUnit = avgWLV;
      // if (configuredUnit && standardUnit && configuredUnit !== "count" && standardUnit !== "count") {
      //   avgWLVInConfiguredUnit = convert(qty)
      //     .from(standardUnit.toLowerCase()) // `L` and `mL` are not supported as is by this library. It must be `l` and `ml`!
      //     .to(configuredUnit);
      // }
      // let targetValueInConfiguredUnit = giveawayForCurrentSKU?.product_target;
      // if (targetValueInConfiguredUnit) {
      //   targetValueInConfiguredUnit = convert(targetValueInConfiguredUnit)
      //     .from(standardUnit)
      //     .to(configuredUnit);
      // }
      // let roundedAverageValue = Helpers.round(avgWLVInConfiguredUnit, 1);
      // state.averageProductGiveaway.value = targetValueInConfiguredUnit ? roundedAverageValue : null; // TODO This is a patch. Fix the root cause (Metric-Calculator, class ContextMetricsCalculator, lines 157-165
      // state.averageProductGiveaway.target = targetValueInConfiguredUnit;
      // state.averageProductGiveaway.unit = configuredUnit;
    },
    setCurrentProductSpeed5m(state, value) {
      state.currentProductSpeed5m = value;
    },
    setProductGiveaway(state, sku) {
      state.giveawaySku = sku;
      const currentSku = sku;
      const weightLengthVolumeQuantity = state.wlvQuantity;
      const giveaway = state.giveaway;

      const wlvQuantityForSKU = weightLengthVolumeQuantity.quantity_by_sku[currentSku];
      const giveawayForCurrentSKU = giveaway.giveaway_by_sku[currentSku];

      let qty = giveawayForCurrentSKU?.giveaway_quantity;
      if (qty === null || qty === undefined) {
        qty = 0.0;
      }
      let configuredUnit = wlvQuantityForSKU?.count_and_quantity?.configured_unit
        ? wlvQuantityForSKU?.count_and_quantity?.configured_unit
        : "count";
      let standardUnit = wlvQuantityForSKU?.count_and_quantity?.standard_unit
        ? wlvQuantityForSKU?.count_and_quantity?.standard_unit
        : "count";
      let quantityInConfiguredUnit = qty;
      if (configuredUnit && standardUnit && configuredUnit !== "count" && standardUnit !== "count") {
        quantityInConfiguredUnit = convert(qty)
          .from(standardUnit)
          .to(configuredUnit);
      }
      state.productGiveaway.value = Helpers.round(quantityInConfiguredUnit, 1);
      state.productGiveaway.valuePercent = Helpers.round(giveawayForCurrentSKU?.giveaway_percentage, 1);
      state.productGiveaway.target = giveawayForCurrentSKU?.product_target;
      state.productGiveaway.targetPercent = giveawayForCurrentSKU?.configured_objective_percentage;
      state.productGiveaway.unit = configuredUnit;
    },
    setAverageProductGiveaway(state, sku) {
      state.giveawaySku = sku;
      const currentSku = sku;
      const weightLengthVolumeQuantity = state.wlvQuantity;
      const giveaway = state.giveaway;

      const wlvQuantityForSKU = weightLengthVolumeQuantity.quantity_by_sku[currentSku];
      const giveawayForCurrentSKU = giveaway.giveaway_by_sku[currentSku];

      let qty = wlvQuantityForSKU?.count_and_quantity?.average_in_standard_unit;
      if (qty === null || qty === undefined) {
        qty = 0.0;
      }
      let configuredUnit = wlvQuantityForSKU?.count_and_quantity?.configured_unit
        ? wlvQuantityForSKU?.count_and_quantity?.configured_unit
        : "count";
      let standardUnit = wlvQuantityForSKU?.count_and_quantity?.standard_unit
        ? wlvQuantityForSKU?.count_and_quantity?.standard_unit
        : "count";
      let quantityInConfiguredUnit = qty;
      if (configuredUnit && standardUnit && configuredUnit !== "count" && standardUnit !== "count") {
        quantityInConfiguredUnit = convert(qty)
          .from(standardUnit)
          .to(configuredUnit);
      }
      let targetValueInConfiguredUnit = giveawayForCurrentSKU?.product_target;
      if (targetValueInConfiguredUnit) {
        targetValueInConfiguredUnit = convert(targetValueInConfiguredUnit)
          .from(standardUnit)
          .to(configuredUnit);
      }
      let roundedAverageValue = Helpers.round(quantityInConfiguredUnit, 1);
      state.averageProductGiveaway.value = targetValueInConfiguredUnit ? roundedAverageValue : null; // TODO This is a patch. Fix the root cause (Metric-Calculator, class ContextMetricsCalculator, lines 157-165
      state.averageProductGiveaway.target = targetValueInConfiguredUnit;
      state.averageProductGiveaway.unit = configuredUnit;
    },
    setJustificationDelayInSeconds(state, value) {
      state.justificationDelayInSeconds = value;
    },
    setCurrentGraphSelection(state, newGraphSelection) {
      state.graphSelection = newGraphSelection;
    },
    setWorkShiftsCoverage(state, value) {
      state.workShiftCoverage = value;
    },
    setProductionRunsCoverage(state, value) {
      state.productionRunCoverage = value;
    },
    setProductionUnitProducts(state, value) {
      state.productionUnitProducts = value;
    },
    setProductionUnitTags(state, value) {
      state.activeProductionUnitTags = value;
    }
  },
};
