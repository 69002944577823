import PackageFeatures from "@/components/PackageFeatures";
import { DIRECTOR_ROLE, OPERATOR_ROLE, PRESENTER_ROLE, SUPERVISOR_ROLE } from "@/store/modules/user";

class Tiles {
  static availabilityTileName = "availability";
  static performanceTileName = "performance";
  static oeeTileName = "oee";
  static allProductsQuantityTileName = "all-products-quantity";
  static currentProductQuantityTileName = "current-product-quantity";
  static productionTileName = "production";
  static currentProductThroughputTileName = "product-throughput";
  static currentProductThroughputPerMinuteTileName = "product-throughput-per-minute";
  static currentProductSpeed5mTileName = "product-speed-5m";
  static currentProductSpeed5mPerMinuteTileName = "product-speed-5m-per-minute";
  static totalUptimeTileName = "total-uptime";
  static totalDowntimeTileName = "total-downtime";
  static productGiveawayTileName = "product-giveaway";
  static productGiveawayPercentTileName = "product-giveaway-percent";
  static productAverageGiveawayTileName = "product-average-giveaway";
  static productSamplingTileName = "product-sampling";
  static inProgressDowntimeTileName = "in-progress-downtime";
  static workOrderTileName = "work-order";
  static lotTileName = "lot";
  static qualityTileName = "quality";
  static rejectQuantityTileName = "reject-quantity";
  static cycleTimeTileName = "cycle-time";
  static cycleQuantityTileName = "cycle-quantity";
  static ooeTileName = "ooe";

  availability = {
    name: Tiles.availabilityTileName,
    requiredFeature: PackageFeatures.availability,
  };
  allProductsQuantity = {
    name: Tiles.allProductsQuantityTileName,
    requiredFeature: PackageFeatures.productQuantity,
  };
  currentProductQuantity = {
    name: Tiles.currentProductQuantityTileName,
    requiredFeature: PackageFeatures.productQuantity,
  };
  performance = {
    name: Tiles.performanceTileName,
    requiredFeature: PackageFeatures.performance,
  };
  oee = {
    name: Tiles.oeeTileName,
    requiredFeature: PackageFeatures.oee,
  };
  currentProductThroughput = {
    name: Tiles.currentProductThroughputTileName,
    requiredFeature: PackageFeatures.performance,
  };
  currentProductThroughputPerMinute = {
    name: Tiles.currentProductThroughputPerMinuteTileName,
    requiredFeature: PackageFeatures.performance,
  };
  currentProductSpeed5m = {
    name: Tiles.currentProductSpeed5mTileName,
    requiredFeature: PackageFeatures.performance,
  };
  currentProductSpeed5mPerMinute = {
    name: Tiles.currentProductSpeed5mPerMinuteTileName,
    requiredFeature: PackageFeatures.performance,
  };
  totalUptime = {
    name: Tiles.totalUptimeTileName,
    requiredFeature: PackageFeatures.availability,
  };
  totalDowntime = {
    name: Tiles.totalDowntimeTileName,
    requiredFeature: PackageFeatures.availability,
  };
  productGiveaway = {
    name: Tiles.productGiveawayTileName,
    requiredFeature: PackageFeatures.giveaway,
  };
  productGiveawayPercent = {
    name: Tiles.productGiveawayPercentTileName,
    requiredFeature: PackageFeatures.giveaway,
  };
  productAverageGiveaway = {
    name: Tiles.productAverageGiveawayTileName,
    requiredFeature: PackageFeatures.giveaway,
  };
  productSampling = {
    name: Tiles.productSamplingTileName,
    requiredFeature: PackageFeatures.giveaway,
  };
  workOrder = {
    name: Tiles.workOrderTileName,
    requiredFeature: PackageFeatures.availability,
  };
  lot = {
    name: Tiles.lotTileName,
    requiredFeature: PackageFeatures.availability,
  };
  production = {
    name: Tiles.productionTileName,
    requiredFeature: PackageFeatures.productQuantity,
  };
  quality = {
    name: Tiles.qualityTileName,
    requiredFeature: PackageFeatures.oee, // The `tile` is available to anybody
  };
  rejectQuantity = {
    name: Tiles.rejectQuantityTileName,
    requiredFeature: PackageFeatures.reject,
  };
  cycleTime = {
    name: Tiles.cycleTimeTileName,
    requiredFeature: PackageFeatures.availability,
  };
  cycleQuantity = {
    name: Tiles.cycleQuantityTileName,
    requiredFeature: PackageFeatures.availability,
  };
  ooe = {
    name: Tiles.ooeTileName,
    requiredFeature: PackageFeatures.oee,
  };

  getSelectableTilesByRole(role) {
    switch (role) {
      case OPERATOR_ROLE:
        return [
          this.availability,
          this.production,
          this.currentProductQuantity,
          this.currentProductThroughput,
          this.currentProductThroughputPerMinute,
          this.currentProductSpeed5m,
          this.currentProductSpeed5mPerMinute,
          this.allProductsQuantity,
          this.totalUptime,
          this.totalDowntime,
          this.performance,
          this.oee,
          this.quality,
          this.rejectQuantity,
          this.productGiveaway,
          this.productGiveawayPercent,
          this.productAverageGiveaway,
          this.cycleTime,
          this.cycleQuantity,
          this.ooe,
        ];
      case SUPERVISOR_ROLE:
      case DIRECTOR_ROLE:
      case PRESENTER_ROLE:
        return [
          this.availability,
          this.production,
          this.currentProductQuantity,
          this.currentProductThroughput,
          this.currentProductThroughputPerMinute,
          this.currentProductSpeed5m,
          this.currentProductSpeed5mPerMinute,
          this.allProductsQuantity,
          this.performance,
          this.oee,
          this.quality,
          this.rejectQuantity,
          this.totalUptime,
          this.totalDowntime,
          this.productGiveaway,
          this.productGiveawayPercent,
          this.productAverageGiveaway,
          this.cycleTime,
          this.cycleQuantity,
          this.ooe,
        ];
      default:
        return [this.allProductsQuantity, this.totalUptime, this.totalDowntime];
    }
  }

  getGiveawayTiles() {
    return [this.productGiveaway, this.productGiveawayPercent, this.productAverageGiveaway];
  }
}

export default new Tiles();

export const inProgressDowntimeTileName = "in-progress-downtime";

export const tileKeyByName = new Map();
tileKeyByName[Tiles.availabilityTileName] = "availability";
tileKeyByName[Tiles.performanceTileName] = "performance";
tileKeyByName[Tiles.oeeTileName] = "oee";
tileKeyByName[Tiles.allProductsQuantityTileName] = "product_quantity";
tileKeyByName[Tiles.currentProductQuantityTileName] = "current_product_quantity";
tileKeyByName[Tiles.currentProductThroughputTileName] = "product_throughput";
tileKeyByName[Tiles.currentProductThroughputPerMinuteTileName] = "product_throughput_per_minute";
tileKeyByName[Tiles.currentProductSpeed5mTileName] = "product_speed_5m";
tileKeyByName[Tiles.currentProductSpeed5mPerMinuteTileName] = "product_speed_5m_per_minute";
tileKeyByName[Tiles.totalUptimeTileName] = "total_uptime";
tileKeyByName[Tiles.totalDowntimeTileName] = "total_downtime";
tileKeyByName[Tiles.productGiveawayTileName] = "product_giveaway";
tileKeyByName[Tiles.productGiveawayPercentTileName] = "product_giveaway_percent";
tileKeyByName[Tiles.inProgressDowntimeTileName] = "in_progress_downtime";
tileKeyByName[Tiles.productAverageGiveawayTileName] = "product_average_giveaway";
tileKeyByName[Tiles.productSamplingTileName] = "product_sampling";
tileKeyByName[Tiles.workOrderTileName] = "work_order";
tileKeyByName[Tiles.lotTileName] = "lot";
tileKeyByName[Tiles.productionTileName] = "production";
tileKeyByName[Tiles.qualityTileName] = "quality";
tileKeyByName[Tiles.rejectQuantityTileName] = "reject_quantity";
tileKeyByName[Tiles.cycleTimeTileName] = "cycle_time";
tileKeyByName[Tiles.cycleQuantityTileName] = "cycle_quantity";
tileKeyByName[Tiles.ooeTileName] = "ooe";
