<template>
  <v-app id="wxapp" :class="{ presenter: isPresenter && isDashboardUrl }" :language="this.language">
    <component :is="layout" v-bind="layoutProps" />
    <v-snackbar
      v-model="snackBar.show"
      :timeout="snackBar.timeout"
      :color="snackBar.status"
      class="wxapp-snackbar--container"
      min-width="300"
      vertical
      absolute
      center
      top
    >
      <p v-if="snackBar.messages && snackBar.messages.length === 1" class="wxapp-snackbar__message mb-0">
        {{ snackBar.messages[0] }}
      </p>
      <fragment v-else-if="snackBar.messages && snackBar.messages.length > 1">
        <v-list tag="ul" :color="snackBar.status" nav>
          <v-list-item tag="li" v-for="(message, i) in snackBar.messages" :key="i" :color="snackBar.status">
            <v-list-item-title class="wxapp-snackbar__message messages">{{ message }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </fragment>

      <template #action="{ attrs }">
        <v-btn v-bind="attrs" @click="hideOperation" class="close-btn" outlined text>
          {{ $t("common.close") }}
        </v-btn>
      </template>
    </v-snackbar>

    <notification-dialog v-model="notificationDialogModel" />
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import NotificationDialog from "@/components/notification/NotificationDialog";

const default_layout = "default-layout";

export default {
  metaInfo() {
    return {
      title: this.$t("meta.title"),
      htmlAttrs: {
        lang: this.language,
      },
      meta: [{ name: "description", content: this.$t("meta.description") }],
    };
  },
  components: {
    NotificationDialog,
  },
  data() {
    return {
      refreshPackageDefinitionsInterval: null,
      refreshNotificationsInterval: null,
    };
  },
  computed: {
    ...mapGetters("user", [
      "isPresenter",
      "language",
      "theme",
      "email",
      "isLoggedIn",
      "username",
      "loggedInUserRole",
      "accountId",
      "userId",
      "account",
    ]),
    ...mapGetters("dashboard", ["currentProductionUnitState"]),
    ...mapGetters("operation", ["snackBar"]),
    ...mapGetters("notifications", ["notificationDialogState"]),
    layout() {
      return this.$route.meta.layout || default_layout;
    },
    layoutProps() {
      return this.$route.meta.layoutProps;
    },
    activeTheme() {
      return this.theme;
    },
    notificationDialogModel: {
      get() {
        return this.notificationDialogState;
      },
      set() {
        this.closeNotificationDialog();
      },
    },
    isDashboardUrl() {
      return this.$route.path.includes("dashboard");
    },
  },

  watch: {
    isLoggedIn() {
      this.initializePendoSession();
    },
    account() {
      this.initializePendoSession();
    },
    activeTheme() {
      this.$vuetify.theme.dark = this.theme === "dark";
    },
    currentProductionUnitState: {
      immediate: true,
      handler(newValue) {
        let root = document.documentElement;

        switch (newValue) {
          case "connection_issues":
            root.style.setProperty("--color-pu-current-state", "var(--color-disconnected)");
            break;
          case "unknown":
            root.style.setProperty("--color-pu-current-state", "var(--color-unknown)");
            break;
          case "out_of_production":
            root.style.setProperty("--color-pu-current-state", "var(--color-outOfProduction)");
            break;
          case "up":
            root.style.setProperty("--color-pu-current-state", "var(--color-uptime)");
            break;
          case "down_unjustified":
            root.style.setProperty("--color-pu-current-state", "var(--color-unjustifiedDowntime)");
            break;
          case "down_unplanned":
            root.style.setProperty("--color-pu-current-state", "var(--color-justifiedDowntime)");
            break;
          case "down_planned":
            root.style.setProperty("--color-pu-current-state", "var(--color-plannedDowntime)");
            break;
          default:
            root.style.setProperty("--color-pu-current-state", "white");
        }
      },
    },
  },
  methods: {
    ...mapActions("operation", ["hideOperation"]),
    ...mapActions("notifications", ["closeNotificationDialog"]),
    ...mapActions("packages", ["fetchPackageDefinitions"]),
    ...mapActions("notifications", ["fetchNotifications"]),
    initializePendoSession() {
      if (!this.isLoggedIn || !this.account) return;
      // TODO: Use this once the API key is in the environment variables to only initialize if in PROD environment
      // if (!process.env.VUE_APP_PENDO_API_KEY) return;
      // need to suppress since pendo is installed via script in index.html therefore eslint can't find it
      // eslint-disable-next-line no-undef
      pendo.initialize({
        visitor: {
          id: this.userId,
          email: this.email,
          full_name: this.username,
          role: this.loggedInUserRole,
        },
        account: {
          id: this.account.id,
          name: this.account.name,
          insights_enabled: this.account.insights_enabled,
          is_tilelytics: this.account.is_tilelytics,
          domain_name: this.account.domain_name,
        },
      });
    },
  },
  mounted() {
    this.initializePendoSession();
    clearInterval(this.refreshPackageDefinitionsInterval);
    this.fetchPackageDefinitions();
    this.refreshPackageDefinitionsInterval = setInterval(() => this.fetchPackageDefinitions(), 3600000); // refresh every hour

    clearInterval(this.refreshNotificationsInterval);
    this.fetchNotifications();
    this.refreshNotificationsInterval = setInterval(() => this.fetchNotifications(), 3600000); // refresh every hour
  },
};
</script>

<style lang="scss" scoped>
.v-application {
  &#wxapp {
    // gradient is set within layouts
    background-color: var(--color-base-theme);
  }

  // Status background-color is dynamic
  .wxapp-snackbar {
    &--container {
      position: fixed;
      z-index: 999;
      text-shadow: var(--white-text-shadow-smallest-level);
      top: 0;

      @media ($wx-isNotMobile) {
        top: 55px;
      }

      // Colors NOT subject to theme mode
      .close-btn {
        color: Black;
        background-color: White;
      }
    }

    /* 
    &__message {
      // if list-item
      &.messages {
        color: var(--color-inverted-text-theme);
      }
    }
    */
  }

  /** - - - - -
   * Presenter
   */
  &.presenter {
    padding: var(--grid-gutter);
    background-color: var(--color-pu-current-state);
    position: relative;
    transition: background-color 0.3s linear;
    overflow: hidden;

    &:after {
      content: "";
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: var(--grid-gutter) solid var(--color-pu-current-state);
      z-index: 6;
      pointer-events: none;
      transition: border-color 0.3s linear;
    }

    ::v-deep .v-application--wrap {
      min-height: calc(100vh - var(--grid-gutter) * 2);
    }

    ::v-deep .v-navigation-drawer {
      max-height: calc(100% - var(--grid-gutter) * 2) !important;
      left: var(--grid-gutter) !important;
      top: var(--grid-gutter) !important;
    }
  }
}
</style>
