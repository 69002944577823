<template>
  <v-menu offset-y left nudge-bottom="8" transition="slide-y-transition">
    <!-- Activator -->
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" icon large class="activator" v-show="isNotificationAvailable">
        <v-icon>mdi-bullhorn-outline</v-icon>
      </v-btn>
    </template>

    <v-container>
      <div class="whats-new-title">{{ $t("notifications.title") }}</div>

      <v-divider class="my-3"></v-divider>

      <v-list dense nav elevation="0" color="var(--color-flat-panel-theme)" class="pa-0">
        <v-list-item
          v-for="(item, index) in notificationItems"
          :key="item.title + index"
          @click="openNotificationDialog(index)"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-container>
  </v-menu>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "NotificationMenu",
  computed: {
    ...mapGetters("notifications", ["notificationItems"]),
    isNotificationAvailable() {
      return this.notificationItems.length > 0;
    },
  },
  methods: {
    ...mapActions("notifications", ["openNotificationDialog"]),
  },
};
</script>

<style lang="scss" scoped>
.activator:before {
  @media ($wx-md-min) {
    opacity: var(--opacity-before-effect-low);
  }
}

.v-menu__content {
  box-shadow: var(--box-shadow-high-elevation);
}

.container {
  padding-top: 20px;
  width: 240px;
  background-color: var(--color-flat-panel-theme);
}

.whats-new-title {
  font-size: 1rem;
  font-weight: 700;
}

.v-list-item {
  min-height: 40px;
}
</style>
