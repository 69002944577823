import http from "../../../BaseServiceHttp";
import { HOUR, SHIFT, PRODUCTION_DAY, PRODUCTION_RUN, DATE_FORMAT } from "@/store/TimeUtils";

class TileService {
  getCurrentProductionRun(productionUnitId) {
    return http.get(`/production-units/${productionUnitId}/metrics/current-production-run`);
  }
  getDataSourceAlerts(factoryId) {
    return http.get(`/factories/${factoryId}/data-sources/alerts`);
  }
  getProductSpeed(productionUnitId, start, end, sku) {
    if (!sku) sku = "";
    const startParam = encodeURIComponent(start);
    const endParam = encodeURIComponent(end);
    const skuParam = encodeURIComponent(sku);
    return http.get(
      `/production-units/${productionUnitId}/metrics/product-speed?start_time=${startParam}&end_time=${endParam}&sku=${skuParam}`,
    );
  }
  getProductionUnitMetrics(
    productionUnitIds,
    type,
    queryStartTime,
    queryEndTime,
    businessDayAsDateTime,
    optionalProductionRunOptions,
  ) {
    let puIdsParam = "";
    if (productionUnitIds instanceof Array) {
      puIdsParam = productionUnitIds.join(",");
    } else {
      puIdsParam = productionUnitIds;
    }
    switch (type) {
      case HOUR:
        return this.getMetricsByHour(puIdsParam, queryStartTime, queryEndTime);
      case SHIFT:
        return this.getMetricsByShift(puIdsParam, queryStartTime, queryEndTime);
      case PRODUCTION_DAY:
        return this.getMetricsByDay(puIdsParam, businessDayAsDateTime);
      case PRODUCTION_RUN:
        return this.getMetricsByProductionRun(puIdsParam, queryStartTime, queryEndTime, optionalProductionRunOptions);
    }
  }
  getMetricsByHour(productionUnitIds, queryStartTime, queryEndTime) {
    const startParam = encodeURIComponent(queryStartTime);
    const endParam = encodeURIComponent(queryEndTime);
    const queryParams = `startTime=${startParam}&endTime=${endParam}&productionUnitIds=${productionUnitIds}`;
    return http.get(`/production-units/metrics/by-hour?${queryParams}`);
  }
  getMetricsByShift(productionUnitIds, queryStartTime, queryEndTime) {
    const startParam = encodeURIComponent(queryStartTime);
    const endParam = encodeURIComponent(queryEndTime);
    const queryParams = `startTime=${startParam}&endTime=${endParam}&productionUnitIds=${productionUnitIds}`;
    return http.get(`/production-units/metrics/by-shift?${queryParams}`);
  }
  getMetricsByDay(productionUnitIds, businessDayAsDateTime) {
    const businessDay = businessDayAsDateTime.toFormat(DATE_FORMAT);
    const endDay = businessDayAsDateTime.plus({ day: 1 }).toFormat(DATE_FORMAT);
    const startParam = encodeURIComponent(businessDay);
    const endParam = encodeURIComponent(endDay);
    const queryParams = `startDay=${startParam}&endDay=${endParam}&productionUnitIds=${productionUnitIds}`;
    return http.get(`/production-units/metrics/by-day?${queryParams}`);
  }
  getMetricsByProductionRun(productionUnitIds, queryStartTime, queryEndTime, optionalProductionRunOptions) {
    const startParam = encodeURIComponent(queryStartTime);
    const endParam = encodeURIComponent(queryEndTime);
    const skuParam = optionalProductionRunOptions?.sku ? `&sku=${encodeURIComponent(optionalProductionRunOptions.sku)}` : "";
    const workOrderIdParam = optionalProductionRunOptions?.workOrderId ? `&work_order_id=${encodeURIComponent(optionalProductionRunOptions.workOrderId)}` : "";
    const lotId = optionalProductionRunOptions?.lotId ? `&lot_id=${encodeURIComponent(optionalProductionRunOptions.lotId)}` : "";
    const queryParams = `startTime=${startParam}&endTime=${endParam}&productionUnitIds=${productionUnitIds}${skuParam}${workOrderIdParam}${lotId}`;
    return http.get(`/production-units/metrics/by-production-run?${queryParams}`);
  }
}

export default new TileService();
