import ErrorHandling from "@/components/ErrorHandling";
import i18n from "@/i18n";
import OverviewService from "@/components/overview/OverviewService";
import DemoService from "@/components/DemoService";
import TileService from "@/components/dashboard/tiles/TileService";
import { toProductionUnitOverview } from "@/store/modules/OverviewHelper";

// noinspection DuplicatedCode
export default {
  namespaced: true,

  state: {
    activeKpiIndex: 0,
    isFullScreen: false,

    productionUnitsStateAndKpis: [],
    productionUnitsSpeed: [],
    activeProductionUnitIdForDetails: null,

    activeProductionUnitsCoverage: [],
    activeProductionUnitsState: [],

    productionUnitDetails: {
      kpis: [],
      downtimes: {
        downtimesToJustify: 0,
        unplannedDowntimes: [],
        plannedDowntimes: [],
      },
      timeDistribution: {
        rawChartData: [],
      },
    },

    // is[placeholder]Loading controls whether a call is currently ongoing. This is so that if for whatever reason
    // a call hangs for a while, we don't start another one before it finishes.

    // is[placeholder]Loaded controls whether there is data to display. View will look at this to either show
    // a skeleton loader or the data. Should only be false on the initial page load and remain true afterwards
    // to avoid flashing cards.
    isOverviewLoading: false,
    isOverviewLoaded: false,
  },

  getters: {
    activeKpiIndex(state) {
      return state.activeKpiIndex;
    },
    availableKpis() {
      // Name in the list of KPIs
      return [
        { queryFor: "oee", title: i18n.t("overview.details.kpis.oee") },
        { queryFor: "availability", title: i18n.t("overview.details.kpis.availability") },
        { queryFor: "performance", title: i18n.t("overview.details.kpis.performance") },
        { queryFor: "quality", title: i18n.t("overview.details.kpis.quality") },
        { queryFor: "ooe", title: i18n.t("overview.details.kpis.ooe") },
        { queryFor: "allProductQuantity", title: i18n.t("overview.details.kpis.allProductQuantity") },
        { queryFor: "currentProductQuantity", title: i18n.t("overview.details.kpis.currentProductQuantity") },
        { queryFor: "ratePerHour", title: i18n.t("overview.details.kpis.ratePerHour") },
        { queryFor: "ratePerMinute", title: i18n.t("overview.details.kpis.ratePerMinute") },
        { queryFor: "speed5Minute", title: i18n.t("overview.details.kpis.speed5Minute") },
        { queryFor: "speed5MinutePerMinute", title: i18n.t("overview.details.kpis.speed5MinutePerMinute") },
      ];
    },
    productionUnitsStateAndKpis(state) {
      return state.productionUnitsStateAndKpis;
    },
    productionUnitsSpeed(state) {
      return state.productionUnitsSpeed;
    },
    productionUnitDowntimes(state) {
      return state.productionUnitDetails.downtimes;
    },
    productionUnitTimeDistribution(state) {
      return state.productionUnitDetails.timeDistribution;
    },
    activeProductionUnitIdForDetails(state) {
      return state.activeProductionUnitIdForDetails;
    },
    isOverviewLoaded(state) {
      return state.isOverviewLoaded;
    },
    isOverviewLoading(state) {
      return state.isOverviewLoading;
    },
    activeProductionUnit(state, getters, rootState, rootGetters) {
      const productionUnits = rootGetters["navigation/activeFactoryProductionUnits"];
      return productionUnits.find((item) => item.id === state.activeProductionUnitIdForDetails);
    },
    isFullScreen(state) {
      return state.isFullScreen;
    },
    activeProductionUnitsState: (state) => {
      return state.activeProductionUnitsState;
    },
    hasAnyActiveTag: (state) => {
      return state.activeProductionUnitsState.some((pu) => pu.tags && pu.tags.length > 0);
    },
  },

  actions: {
    setActiveKpiIndex({ commit }, activeKpiIndex) {
      commit("setActiveKpiIndex", activeKpiIndex);
    },

    fetchOverview({ dispatch, state, rootGetters, commit }) {
      let isUserLoggedIn = rootGetters["user/isLoggedIn"];
      let hasOverviewAccess = rootGetters["user/hasOverviewAccess"];
      let activeFactory = rootGetters["navigation/activeFactory"];
      let activeFactoryId = activeFactory.id;

      dispatch("navigation/setCurrentDateTime", null, { root: true });
      commit("setLoader", ["isOverviewLoading", true]);
      let productionUnits = activeFactory.productionUnits;
      let productionUnitIds = activeFactory.productionUnits.map((pu) => pu.id);
      if (isUserLoggedIn && activeFactory && hasOverviewAccess) {
        dispatch("fetchProductionUnitsCoverage", { activeFactoryId, productionUnits })
          .then(() => {
            dispatch("fetchProductionUnitsState", { activeFactoryId, productionUnits })
              .then(() => {
                const productionUnitStates = state.activeProductionUnitsState;
                dispatch("fetchProductionUnitsKPIs", {
                  productionUnitIds,
                  activeFactoryId,
                  productionUnitStates,
                  productionUnits,
                })
                  .then(() => {
                    commit("setLoader", ["isOverviewLoading", false]);
                    if (activeFactoryId === rootGetters["navigation/activeFactory"].id) {
                      // only set loaded to true if the data that was loaded is for the correct factory
                      // if not, don't set the loader in case the correct call has already set it
                      commit("setLoader", ["isOverviewLoaded", true]);
                    }
                  })
                  .catch(() => {
                    commit("setLoader", ["isOverviewLoading", false]);
                    commit("setLoader", ["isOverviewLoaded", true]);
                  });
              })
              .catch(() => {
                commit("setLoader", ["isOverviewLoading", false]);
                commit("setLoader", ["isOverviewLoaded", true]);
              });
          })
          .catch(() => {
            commit("setLoader", ["isOverviewLoading", false]);
            commit("setLoader", ["isOverviewLoaded", true]);
          });
      }
    },

    fetchProductionUnitsCoverage({ commit, rootGetters }, { activeFactoryId, productionUnits }) {
      const startTime = rootGetters["navigation/startISO"];
      const endTime = rootGetters["navigation/endISO"];
      const productionUnitIds = productionUnits.map((pu) => pu.id);
      return OverviewService.getProductionUnitsCoverage(activeFactoryId, productionUnitIds, startTime, endTime)
        .then((httpResponse) => {
          // if IDs don't match, factory was changed while overview was fetching. Don't set this data as it's not good anymore
          if (activeFactoryId !== rootGetters["navigation/activeFactory"].id) return;
          if (httpResponse.status === 200) {
            commit("setActiveProductionUnitsCoverage", httpResponse.data.production_units_coverage);
          }
        })
        .catch((error) => {
          commit(
            "operation/showOperationError",
            ErrorHandling.buildErrorsMessages(error.response, (code) => {
              switch (code) {
                case "DSH_COV_GET_10007":
                case "DSH_COV_GET_10008":
                  return null; // No error messages for this backend error code. It's a race condition.
                default:
                  return i18n.t("common.errors.default", { code: error });
              }
            }),
            { root: true },
          );
        });
    },

    async fetchProductionUnitsState({ commit, state, rootGetters }, { activeFactoryId, productionUnits }) {
      const email = rootGetters["user/email"];
      const isLiveData = rootGetters["navigation/isLiveData"];
      const startTime = rootGetters["navigation/startISO"];
      const endTime = rootGetters["navigation/endISO"];

      if (isLiveData) {
        return OverviewService.getProductionUnitsCurrentState(activeFactoryId, startTime, endTime)
          .then((httpResponse) => {
            // if IDs don't match, factory was changed while overview was fetching. Don't set this data as it's not good anymore
            if (activeFactoryId !== rootGetters["navigation/activeFactory"].id) return;
            if (httpResponse.status === 200) {
              let productionUnitStates = httpResponse.data.map((o, index) => {
                const productionUnit = productionUnits.find((pu) => pu.id === o.production_unit_id);
                let puStatus = o.production_unit_state.toLowerCase();
                if (!isLiveData) {
                  puStatus = "carbon-frozen"; // carbon frozen ? Yes, like Han Solo in `The Empire Strikes Back` :D
                }
                return {
                  kpiSelected: state.activeKpiIndex,
                  puId: o.production_unit_id,
                  puName: DemoService.maskProductionUnitNameIfNecessary(email, productionUnit.name, index + 1),
                  puStatus: puStatus,
                  productSku: o.current_product_sku,
                  productName: DemoService.maskProductNameIfNecessary(email, o.current_product_name),
                  tags: mapTags(o.tags),
                };
              });

              let index = productionUnitStates.length;
              productionUnits.forEach((pu) => {
                const puState = productionUnitStates.find((puState) => puState.puId === pu.id);
                if (puState === null || puState === undefined) {
                  productionUnitStates.push({
                    kpiSelected: state.activeKpiIndex,
                    puId: pu.id,
                    puName: DemoService.maskProductionUnitNameIfNecessary(email, pu.name, index + 1),
                    puStatus: "carbon-frozen",
                    productSku: null,
                    productName: DemoService.maskProductNameIfNecessary(email, null),
                    tags: [],
                  });
                }
              });

              commit("setActiveProductionUnitsState", productionUnitStates);
            }
          })
          .catch((error) => {
            commit(
              "operation/showOperationError",
              ErrorHandling.buildErrorsMessages(error.response, (code) =>
                i18n.t("common.errors.default", { code: code }),
              ),
              { root: true },
            );
          });
      } else {
        // Past data
        const productionUnits = rootGetters["navigation/activeFactoryProductionUnits"];
        const productionUnitStates = state.activeProductionUnitsCoverage.map((puCoverage, index) => {
          const productionUnit = productionUnits.find((pu) => pu.id === puCoverage.production_unit_id);

          let lastProductionRun = {
            sku: null,
            product_name: null,
          };
          if (puCoverage.production_runs_coverage && puCoverage.production_runs_coverage.length > 0) {
            lastProductionRun = puCoverage.production_runs_coverage[puCoverage.production_runs_coverage.length - 1];
          }
          return {
            kpiSelected: state.activeKpiIndex,
            puId: productionUnit.id,
            puName: DemoService.maskProductionUnitNameIfNecessary(email, productionUnit.name, index + 1),
            puStatus: "carbon-frozen", // carbon frozen ? Yes, like Han Solo in `The Empire Strikes Back` :D
            productSku: lastProductionRun.sku,
            productName: DemoService.maskProductNameIfNecessary(email, lastProductionRun.product_name),
          };
        });
        commit("setActiveProductionUnitsState", productionUnitStates);
        return new Promise((resolve) => resolve("done"));
      }
    },

    async fetchProductionUnitsKPIs(
      { commit, dispatch, state, rootGetters },
      { productionUnitIds, activeFactoryId, productionUnitStates, productionUnits },
    ) {
      if (productionUnitStates === null || productionUnitStates === undefined || productionUnitStates.length === 0) {
        return;
      }
      return TileService.getProductionUnitMetrics(
        productionUnitIds,
        rootGetters["navigation/timeFrame"],
        rootGetters["navigation/startISO"],
        rootGetters["navigation/endISO"],
        rootGetters["navigation/date"], // Business Day as selected by the user
        null,
      )
        .then((httpResponse) => {
          // if IDs don't match, factory was changed while overview was fetching. Don't set this data as it's not good anymore
          if (activeFactoryId !== rootGetters["navigation/activeFactory"].id) return;
          const email = rootGetters["user/email"];

          let productionUnitOverviews = [];
          if (httpResponse.status === 200) {
            productionUnitOverviews = httpResponse.data.map((puMetrics, index) => {
              let puOverview = toProductionUnitOverview(puMetrics, productionUnitStates, productionUnits);
              // Add property `kpiSelected`
              puOverview["kpiSelected"] = state.activeKpiIndex;
              // Overwrite the `puName` for demo purposes?
              puOverview["puName"] = DemoService.maskProductionUnitNameIfNecessary(email, puOverview.puName, index + 1);
              return puOverview;
            });
            const sortedList = productionUnitOverviews.sort((o1, o2) => {
              if (o1.puName < o2.puName) return -1;
              if (o1.puName > o2.puName) return 1;
              return 0;
            });
            const forceSpeedRetrieval = false;
            dispatch("fetchProductionUnitsSpeed5Minutes", { activeFactoryId, forceSpeedRetrieval });
            commit("setProductionUnitsStateAndKpis", sortedList);
          }
        })
        .catch((error) => {
          commit(
            "operation/showOperationError",
            ErrorHandling.buildErrorsMessages(error.response, (code) =>
              i18n.t("common.errors.default", { code: code }),
            ),
            { root: true },
          );
        });
    },

    fetchProductionUnitsSpeed5Minutes(
      { commit, state, getters, rootGetters },
      { activeFactoryId, forceSpeedRetrieval },
    ) {
      const isLiveData = rootGetters["navigation/isLiveData"];
      if (!isLiveData) {
        commit("setProductionUnitsSpeed", []);
        return;
      }
      if (!forceSpeedRetrieval) {
        // We will fetch the Speed only if the KPI is selected
        const requestedKPI = getters.availableKpis[state.activeKpiIndex].queryFor;
        if (requestedKPI !== "speed5Minute" && requestedKPI !== "speed5MinutePerMinute") {
          // no need to query the Speed, it is not selected
          commit("setProductionUnitsSpeed", []);
          return;
        }
      }
      if (!activeFactoryId) return;
      OverviewService.getProductionUnitsSpeed(
        activeFactoryId,
        rootGetters["navigation/startISO"],
        rootGetters["navigation/endISO"],
      )
        .then((httpResponse) => {
          if (httpResponse.status === 200) {
            commit("setProductionUnitsSpeed", httpResponse.data);
          }
        })
        .catch((error) => {
          commit(
            "operation/showOperationError",
            ErrorHandling.buildErrorsMessages(error.response, (code) =>
              i18n.t("common.errors.default", { code: code }),
            ),
            { root: true },
          );
        });
    },

    fetchProductionUnitOverview({ commit, getters, rootGetters, dispatch }, productionUnitId) {
      dispatch("navigation/setCurrentDateTime", null, { root: true });
      commit("setActiveProductionUnitIdForDetails", productionUnitId);
      const forceSpeedRetrieval = true;
      let activeFactory = rootGetters["navigation/activeFactory"];
      let activeFactoryId = activeFactory.id;
      dispatch("fetchProductionUnitsSpeed5Minutes", { activeFactoryId, forceSpeedRetrieval });

      let startDateTime = rootGetters["navigation/startDateTime"];
      let endDateTime = rootGetters["navigation/endDateTime"];
      if (
        startDateTime.minute > 0 ||
        startDateTime.second > 0 ||
        startDateTime.millisecond > 0 ||
        endDateTime.minute > 0 ||
        endDateTime.second > 0 ||
        endDateTime.millisecond > 0
      ) {
        return;
      }

      OverviewService.getProductionUnitOverviewDetails(
        productionUnitId,
        rootGetters["navigation/startISO"],
        rootGetters["navigation/endISO"],
      )
        .then((puOverviewDetailsHttpResponse) => {
          if (puOverviewDetailsHttpResponse.status === 200) {
            const puOverviewDetails = puOverviewDetailsHttpResponse.data;

            const topPlannedDowntimeReasons = puOverviewDetails.top_planned_downtime_reasons.map((r) => ({
              durationSec: r.total_downtime_duration_seconds,
              reason: "[" + r.reason_code + "] " + r.reason_name,
            }));
            const topUnplannedDowntimeReasons = puOverviewDetails.top_unplanned_downtime_reasons.map((r) => ({
              durationSec: r.total_downtime_duration_seconds,
              reason: "[" + r.reason_code + "] " + r.reason_name,
            }));

            let delayInSec = getters.activeProductionUnit.downtimeJustificationDelay;
            const delayInMillis = delayInSec && delayInSec > 0 ? delayInSec * 1000 : 0;
            const downtimesToJustify = puOverviewDetails.unjustified_downtimes.filter(
              (d) => d.end_time - d.start_time >= delayInMillis,
            ).length;
            const downtimes = {
              downtimesToJustify: downtimesToJustify,
              unplannedDowntimes: topUnplannedDowntimeReasons,
              plannedDowntimes: topPlannedDowntimeReasons,
            };

            let distributions = [];
            if (puOverviewDetails.time_distribution.uptime > 0) {
              distributions.push({
                type: "active",
                seconds: puOverviewDetails.time_distribution.uptime / 1000,
              });
            }
            if (puOverviewDetails.time_distribution.downtime_planned > 0) {
              distributions.push({
                type: "planned-downtime",
                seconds: puOverviewDetails.time_distribution.downtime_planned / 1000,
              });
            }
            if (puOverviewDetails.time_distribution.downtime_unplanned > 0) {
              distributions.push({
                type: "justified-downtime",
                seconds: puOverviewDetails.time_distribution.downtime_unplanned / 1000,
              });
            }
            if (puOverviewDetails.time_distribution.downtime_unjustified > 0) {
              distributions.push({
                type: "unjustified-downtime",
                seconds: puOverviewDetails.time_distribution.downtime_unjustified / 1000,
              });
            }
            if (puOverviewDetails.time_distribution.out_of_production_time > 0) {
              distributions.push({
                type: "out-of-production",
                seconds: puOverviewDetails.time_distribution.out_of_production_time / 1000,
              });
            }
            if (puOverviewDetails.time_distribution.unknown_time > 0) {
              distributions.push({
                type: "unknown",
                seconds: puOverviewDetails.time_distribution.unknown_time / 1000,
              });
            }
            const timeDistribution = {
              rawChartData: distributions,
              rawTimings: puOverviewDetails.time_distribution,
            };
            const puDetails = {
              downtimes: downtimes,
              timeDistribution: timeDistribution,
            };
            commit("setProductionUnitDetails", puDetails);
          }
        })
        .catch((error) =>
          commit(
            "operation/showOperationError",
            ErrorHandling.buildErrorsMessages(error.response, (code) =>
              i18n.t("common.errors.default", { code: code }),
            ),
            { root: true },
          ),
        );
    },

    setActiveProductionUnitIdForDetails({ commit }, puId) {
      commit("setActiveProductionUnitIdForDetails", puId);
    },

    clearProductionUnitIdForDetails({ commit }) {
      commit("setActiveProductionUnitIdForDetails", null);
    },

    setIsFullScreen({ commit }, value) {
      commit("setIsFullScreen", value);
    },

    resetOverviewLoaders({ commit }) {
      commit("setLoader", ["isOverviewLoaded", false]);
    },
  },

  mutations: {
    setLoader(state, [loader, value]) {
      state[loader] = value;
    },
    setActiveKpiIndex(state, activeKpiIndex) {
      state.activeKpiIndex = activeKpiIndex;
    },
    setProductionUnitsStateAndKpis(state, pu) {
      state.productionUnitsStateAndKpis = pu;
    },
    setProductionUnitsSpeed(state, puSpeed) {
      state.productionUnitsSpeed = puSpeed;
    },
    setProductionUnitDetails(state, puDetails) {
      state.productionUnitDetails = puDetails;
    },
    setActiveProductionUnitIdForDetails(state, puId) {
      state.activeProductionUnitIdForDetails = puId;
    },
    setActiveProductionUnitsCoverage(state, value) {
      state.activeProductionUnitsCoverage = value;
    },
    setActiveProductionUnitsState(state, value) {
      state.activeProductionUnitsState = value;
    },
    setIsFullScreen(state, value) {
      state.isFullScreen = value;
    },
  },
};

function mapTags(tags) {
  return tags
    ? tags.map((tag) => ({
        id: tag.tag_id,
        since: tag.since,
      }))
    : [];
}
