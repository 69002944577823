import http from "../../../BaseServiceHttp";

class ProductionRunService {
  createProductionRun(productionUnitId, productionRunCreation) {
    return http.post(`/production-units/${productionUnitId}/production-runs`, productionRunCreation);
  }
  deleteProductionRun(productionUnitId, productionUnitEventId) {
    return http.delete(`/production-units/${productionUnitId}/production-runs/${productionUnitEventId}`);
  }
  updateProductionRun(productionUnitId, productionUnitEventId, productionRunUpdate) {
    return http.put(`/production-units/${productionUnitId}/production-runs/${productionUnitEventId}`, productionRunUpdate);
  }
}

export default new ProductionRunService();
