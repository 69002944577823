import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueMeta from "vue-meta";

import DefaultLayout from "./layouts/DefaultLayout";
import SimpleLayout from "./layouts/SimpleLayout";
import { Fragment } from "vue-fragment";
import i18n, { setLanguageToLocalStorage } from "./i18n";

import "@/styles/global.scss";
import "@/styles/typography.scss";
import "@/styles/wx_variables.scss";

// import * as authRedirect from "./authentication/authRedirect";
// import RouteService from "@/router/RouteService";
//
import { VueMaskDirective } from "v-mask";
// import routeService from "@/router/RouteService";
import UserService from "@/components/user/UserService";
import { setThemeToLocalStorage } from "@/components/Theme";
import ErrorHandling from "@/components/ErrorHandling";
Vue.directive("mask", VueMaskDirective);

Vue.component("default-layout", DefaultLayout);
Vue.component("simple-layout", SimpleLayout);
Vue.component("fragment", Fragment);

Vue.config.productionTip = false;

// Route guard
router.beforeEach((to, from, next) => {
  // const user = authRedirect.getUser();
  // const isLoggedIn = store.getters["user/isLoggedIn"];
  //
  // if (user && !isLoggedIn) {
  //   authRedirect.setUser(user);
  //   authRedirect.setUserProfile(user);
  // }
  // if (to.matched.some((record) => record.meta.requiresAuth)) {
  //   if (!user) {
  //     localStorage.setItem("pathBeforeLogin", to.fullPath);
  //     next(RouteService.toHome());
  //     return;
  //   }
  // } else if (user) {
  //   next(RouteService.toUserHome());
  //   return;
  // }
  // if (to.matched.some((record) => record.meta.requiresAdmin) && !store.state.user.isAdmin) {
  //   next(RouteService.toUserHome());
  //   return;
  // }
  // if (from.name === "login-callback") {
  //   const originalURL = localStorage.getItem("pathBeforeLogin");
  //   if (originalURL) {
  //     localStorage.removeItem("pathBeforeLogin");
  //     if (originalURL.includes("dashboard")) {
  //       const regex = new RegExp(
  //         "^\\/dashboard\\/((\\{){0,1}[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}(\\}){0,1}).*",
  //       );
  //       const captureGroups = regex.exec(originalURL);
  //       if (captureGroups && captureGroups.length >= 2) {
  //         const productionUnitId = captureGroups[1];
  //         next(routeService.toDashboard(productionUnitId));
  //         return;
  //       }
  //     }
  //   }
  // }
  const z = to.query["z"];
  const u = to.query["u"];
  if (z && u) {
    store.commit("user/setZToken", z);
    store.commit("user/setUToken", u);
    store.commit("user/setUser", {
      userId: u,
      role: "supervisor",
      isAdmin: false,
      isFinancialAdmin: false,
    });
    loadPackageDefinitions();
    loadUserProfile();
  }
  next();
});

async function loadPackageDefinitions() {
  await store.dispatch("packages/fetchPackageDefinitions");
}
async function loadUserProfile() {
  store.commit("user/setTilelyticsEnabled", false);
  await store.dispatch("user/fetchUserFactories");
  await loadUserPreferences();
}

async function loadUserPreferences() {
  let preferences;
  await UserService.getUserPreferences()
    .then((response) => {
      if (response.status === 200) {
        preferences = response.data;
      }
    })
    .catch((error) => {
      store.commit(
        "operation/showOperationError",
        ErrorHandling.buildErrorsMessages(error.response, () => i18n.t("common.errors.loadPreferences")),
        { root: true },
      );
    });
  let theme = store.getters["user/theme"];
  if (preferences) {
    theme = preferences.theme;
    let selectedProductionUnitId = preferences.dashboards.selected_production_unit_id;
    const tiles = preferences.dashboards.production_units.find((pu) => pu.id === selectedProductionUnitId).tiles;
    const factories = store.getters["user/factories"];
    await store.dispatch("navigation/setActiveFactory", factories[0]);
    store.commit("dashboard/setActiveProductionUnitId", selectedProductionUnitId);
    store.commit("dashboard/setCurrentTileSelection", UserService.buildTileSelection(tiles));
    store.commit("user/setPreferences", preferences);
  }
  setLanguageToLocalStorage(i18n.locale);
  setThemeToLocalStorage(theme === "dark");
  i18n.locale = store.getters["user/language"];
}

var filter = function(text, stop, clamp){  return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')};
Vue.filter('truncate', filter);
Vue.use(VueMeta);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
